export interface Route {
  path: string;
}

export interface ClientRoutes {
  home: Route;
  unsubscribe: Route;
  aboutUs: Route;
  products: Route;
  productsRetail: Route;
  productsWholesale: Route;
  product: Route;
  productRetailCategory: Route;
  retail: Route;
  wholesale: Route;
  productWholesaleCategory: Route;
  productRetailCategoryTable: Route;
  productWholesaleCategoryTable: Route;
  productDetails: Route;
  pool: Route;
  hosting: Route;
  blog: Route;
  blogPost: Route;
  helpCenter: Route;
  helpCenterQA: Route;
  helpCenterFAQ: Route;
  contacts: Route;
  cart: Route;
  cartInformation: Route;
  cartShipping: Route;
  cartPayment: Route;
  profile: Route;
  profileWishlist: Route;
  profileOrders: Route;
  compare: Route;
  cookiesPolicy: Route;
  publicOffer: Route;
  userAgreement: Route;
  emptyPage: Route;
  notFound: Route;
  stub: Route;
}

export interface AdminRoutes {
  home: Route;
  adminPanel: Route;
  authorization: Route;
  profile: Route;
  orders: Route;
  order: Route;
  products: Route;
  productCategories: Route;
  product: Route;
  productEdit: Route;
  articles: Route;
  article: Route;
  clients: Route;
  articleEdit: Route;
  subscribers: Route;
  newsletterWorkshop: Route;
  recipientList: Route;
  client: Route;
  admins: Route;
  adminEmails: Route;
  messages: Route;
  messagesList: Route;
  contactUs: Route;
  faq: Route;
  qa: Route;
  bloggersAndCompanies: Route;
  referralCodes: Route;
  clientTracking: Route;
  contacts: Route;
  auditLogs: Route;
  shippingInfo: Route;
  notFound: Route;
}

export const routesClient: ClientRoutes = {
  home: { path: '/' },
  unsubscribe: { path: '/unsubscribe' },
  aboutUs: { path: '/about-us' },
  products: { path: '/products' },
  productsRetail: { path: 'products/retail' },
  productsWholesale: { path: 'products/wholesale' },
  product: { path: '/product' },
  productRetailCategory: { path: '/products/retail/:category' },
  retail: { path: '/retail' },
  wholesale: { path: '/wholesale' },
  productWholesaleCategory: { path: '/products/wholesale/:category' },
  productRetailCategoryTable: { path: '/products/retail/:category/table' },
  productWholesaleCategoryTable: { path: '/products/wholesale/:category/table' },
  productDetails: { path: '/product/:url' },
  pool: { path: '/pool' },
  hosting: { path: '/hosting' },
  blog: { path: '/blog' },
  blogPost: { path: '/blog/:url' },
  helpCenter: { path: '/help-center' },
  helpCenterQA: { path: 'help-center/qa' },
  helpCenterFAQ: { path: 'help-center/faq' },
  contacts: { path: '/contacts' },
  cart: { path: '/cart' },
  cartInformation: { path: '/cart/information' },
  cartShipping: { path: '/cart/information/shipping' },
  cartPayment: { path: '/cart/information/shipping/payment' },
  profile: { path: '/profile' },
  profileWishlist: { path: '/profile/wishlist' },
  profileOrders: { path: '/profile/orders' },
  compare: { path: '/compare' },
  cookiesPolicy: { path: '/cookies-policy' },
  publicOffer: { path: '/public-offer' },
  userAgreement: { path: '/user-agreement' },
  emptyPage: { path: '/*' },
  notFound: { path: '/404' },
  stub: { path: '#' },
};

export const routesAdmin: AdminRoutes = {
  home: { path: '/' },
  adminPanel: { path: '/admin' },
  profile: { path: '/profile' },
  orders: { path: '/orders' },
  order: { path: '/orders/:id' },
  products: { path: '/products' },
  productCategories: { path: '/products/categories' },
  product: { path: '/products/product' },
  productEdit: { path: '/products/product/:id' },
  articles: { path: '/articles' },
  article: { path: '/articles/article' },
  articleEdit: { path: '/articles/article/:id' },
  clients: { path: '/clients' },
  subscribers: { path: '/clients/subscribers' },
  newsletterWorkshop: { path: '/clients/newsletter_workshop' },
  recipientList: { path: '/clients/recipient_list' },
  client: { path: '/clients/:id' },
  admins: { path: '/admins' },
  adminEmails: { path: '/admins/emails' },
  messages: { path: '/messages' },
  messagesList: { path: '/messages' },
  contactUs: { path: '/messages/contact-us' },
  faq: { path: '/help-center' },
  qa: { path: '/help-center/qa' },
  bloggersAndCompanies: { path: '/referral-program' },
  referralCodes: { path: '/referral-program/referral-codes' },
  clientTracking: { path: '/referral-program/client-tracking' },
  contacts: { path: '/contacts' },
  auditLogs: { path: '/audit-logs' },
  shippingInfo: { path: '/shipping-info' },
  authorization: { path: '/*' },
  notFound: { path: '/*' },
};
