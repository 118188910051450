import './Card.scss';

import { SnackbarMessage, useSnackbar, VariantType } from 'notistack';
import React, { MouseEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import CartProdIcon from '../../../general/assets/svg/CartProdIcon';
import EnergyConsumption from '../../../general/assets/svg/EnergyConsumption';
import EnergyEfficiency from '../../../general/assets/svg/EnergyEfficiency';
import HashRateIcon from '../../../general/assets/svg/HashRateIcon';
import LocationMarkerIcon from '../../../general/assets/svg/LocationMarkerIcon';
import { addCartItem } from '../../../general/redux/reducers/ClientCart-Reducer';
import { RootState } from '../../../general/redux/store';
import { images } from '../../../general/StaticData';
import { ProductMax } from '../../../general/types/types';
import WishlistBtn from '../buttons/wishlist/WishlistBtn';
import CardSlider from '../sliders/card-slider/CardSlider';

interface CardProps {
  isMining: boolean;
  product: ProductMax;
  categoryId: string;
  setIsAuth: (value: boolean) => void;
}

const Card: React.FC<CardProps> = ({ isMining, product, categoryId, setIsAuth }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state: RootState) => state.pc.pc);

  const { enqueueSnackbar } = useSnackbar();
  const [isSnackbarActive, setIsSnackbarActive] = useState(false);

  const showSnackbar = (message: SnackbarMessage, variant: VariantType) => {
    if (!isSnackbarActive) {
      setIsSnackbarActive(true);
      enqueueSnackbar(message, {
        variant,
        onClose: () => {
          setIsSnackbarActive(false);
        },
      });
    }
  };

  const BtnClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const existingItemIndex = cart.findIndex((item: any) => item.p.id === product?.id);
    if (existingItemIndex !== -1) {
      if (cart[existingItemIndex].c < 999) {
        showSnackbar('Added to cart', 'success');
        dispatch(addCartItem({ p: product, c: 1 }));
      }
    } else {
      showSnackbar('Added to cart', 'success');
      dispatch(addCartItem({ p: product, c: 1 }));
    }
  };

  const imageLink = async (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const imgNavigate = async (e: MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).tagName === 'IMG') {
      navigation(`/product/${product.url}`);
    }
  };

  return (
    <>
      <div className="card">
        <Link to={`/product/${product.url}`}>
          <div className="card-content">
            <div className="card-top">
              <div className="card-top__label">
                {isMining && (
                  <>
                    <span>{product?.condition}</span>
                    {product?.percentageDiscount !== 0 && (
                      <div className="card-images__new-label">
                        {product?.percentageDiscount === 0
                          ? ''
                          : `-${product?.percentageDiscount}%`}
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="card-top__icon-actions">
                {/* <CompareBtn product={product} categoryId={categoryId} /> */}
                <WishlistBtn setIsAuthModalOpen={setIsAuth} productId={product.id} />
              </div>
            </div>
            <div className="card-images" onClick={imageLink} onMouseUp={imgNavigate}>
              <div className="card-images__slider">
                <div className="card-images__slider__container">
                  <CardSlider
                    images={
                      product?.images?.length === 0 ? [{ url: images.defaultImg }] : product?.images
                    }
                  />
                </div>
              </div>
            </div>
            <div className="card-name">
              <div className="card-name__firm">{product?.manufacturer}</div>
              <div className="card-name__text">{product?.name}</div>
            </div>
            <div className="card-price-status">
              <div className="card-price-status__price">
                {product?.percentageDiscount === 0 ? (
                  `${product?.price.toFixed(2)}$`
                ) : (
                  <>
                    <span className="card-price-status__price-without-discount">{`${product?.price.toFixed(
                      2,
                    )}$`}</span>
                    <span className="card-price-status__price-with-discount">{`${(
                      product?.price -
                      product?.price * (product?.percentageDiscount / 100)
                    ).toFixed(2)}$`}</span>
                  </>
                )}
              </div>
              <div className="card-price-status__status">
                <span>{product?.amount === 0 ? 'Pre-order' : 'Available'}</span>
                {product?.batch !== null &&
                  product?.batch !== undefined &&
                  product?.batch !== '' && (
                    <span>
                      {new Date(product?.batch)?.toLocaleString('en-US', {
                        month: 'long',
                        year: 'numeric',
                      })}{' '}
                      Batch
                    </span>
                  )}
              </div>
            </div>
            <div className="card-button">
              <button className="card-button__btn" onClick={(e) => BtnClick(e)}>
                <CartProdIcon />
                <span>Add to cart</span>
              </button>
            </div>
            {isMining && (
              <div className="card-info">
                <span className="card-info__text">
                  <LocationMarkerIcon fill="white" />
                  {product?.countryLocation}
                </span>
                <div className="card-info__icons">
                  <div className="card-info__icons-icon">
                    <HashRateIcon />
                    <span className="value">{product?.hashrate.split(' ')[0]}</span>
                    <span className="unit">{product?.hashrate.split(' ')[1]}</span>
                  </div>
                  <div className="card-info__icons-icon">
                    <EnergyConsumption />
                    <span className="value">{product?.energyConsumption}</span>
                    <span className="unit">W</span>
                  </div>
                  <div className="card-info__icons-icon">
                    <EnergyEfficiency />
                    <span className="value">{product?.energyEfficiency}</span>
                    <span className="unit">J/TH</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Link>
      </div>
    </>
  );
};

export default Card;
