import './Cart.scss';

import { useGoogleOneTapLogin } from '@react-oauth/google';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import CartSectionEmpty from '../../../general/assets/svg/CartSectionEmpty';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import useGoogleCredentials from '../../../general/hooks/useGoogleCredentials';
import { useResize } from '../../../general/hooks/useResize';
import { queryKeys } from '../../../general/queryKeys';
import { clearCart } from '../../../general/redux/reducers/ClientCart-Reducer';
import { setInfo, setType, types } from '../../../general/redux/reducers/Error-Reducer';
import { RootState } from '../../../general/redux/store';
import { routesClient } from '../../../general/routes';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createProductsService from '../../../general/services/products';
import { environment, environments } from '../../../general/StaticData';
import { ShopLink } from '../../../general/types/types';
import { formatToTwoPlaces } from '../../../general/utils/Validations';
import { createCredentials } from '../../components/authorization/google-authorization/GoogleAuth';
import ModalWindow from '../../components/authorization/modal-window/ModalWindow';
import ListTable from './ListTable';
import TableRow from './TableRow';

const Cart: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setGoogleCredentials = useGoogleCredentials();

  const [isUserAgreementChecked, setIsUserAgreementChecked] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const { width } = useResize();

  const { data: retailCount } = useCustomQuery<ShopLink>(
    queryKeys.PRODUCT_CATEGORIES_RETAIL,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      const obj = {
        priceCategoryCount: data?.reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue?.retailProductsCount,
          0,
        ),
        machineCategoryCount: data.length === 1 ? data[0].url : null,
      };

      return obj;
    },
  );

  const { data: wholesaleCount } = useCustomQuery<ShopLink>(
    queryKeys.PRODUCT_CATEGORIES_WHOLESALE,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      const obj = {
        priceCategoryCount: data?.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + currentValue?.wholesaleProductsCount,
          0,
        ),
        machineCategoryCount: data.length === 1 ? data[0].url : null,
      };

      return obj;
    },
  );

  const deleteAllRow = () => {
    dispatch(clearCart());
  };

  const userCart = useSelector((state: RootState) => state.pc);
  const userId = useSelector((state: RootState) => state.clps.cp2);

  const [totalPrice, setTotalPrice] = useState(
    userCart.pc
      .map((item: any) => {
        if (item.p.percentageDiscount > 0) {
          return item.p.price * item.c - item.p.price * item.c * (item.p.percentageDiscount / 100);
        } else {
          return item.p.price * item.c;
        }
      })
      .reduce((acc: any, num: any) => acc + num, 0),
  );

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      setGoogleCredentials(createCredentials(credentialResponse));
    },
    cancel_on_tap_outside: false,
    disabled: userId !== '',
    use_fedcm_for_prompt:
      environment === environments.DEV_SITE || environment === environments.PROD,
    onError: () => {
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: 'Ooops... Login Failed' },
        }),
      );
    },
  });

  useEffect(() => {
    setTotalPrice(
      userCart.pc
        .map((item: any) => {
          if (item.p.percentageDiscount > 0) {
            return (
              item.p.price * item.c - item.p.price * item.c * (item.p.percentageDiscount / 100)
            );
          } else {
            return item.p.price * item.c;
          }
        })
        .reduce((acc: any, num: any) => acc + num, 0),
    );
  }, [userCart]);

  const changeChecboxTerms = (e: ChangeEvent<HTMLInputElement>) => {
    setIsUserAgreementChecked(!isUserAgreementChecked);
  };

  const createOrder = () => {
    if (userId === '') {
      setIsAuth(true);
      return;
    }

    navigate(routesClient.cartInformation.path);
  };

  return (
    <>
      {isAuth && <ModalWindow isActive={setIsAuth} />}
      <div className="cart-section page-container">
        <div className="cart-section-top">
          <div className="cart-section-top__title">CART</div>
        </div>
        {userCart.count === 0 ? (
          <div className="cart-section-empty">
            <CartSectionEmpty />

            <div className="cart-section-empty__text">
              Your cart is empty
              {userId === '' ? (
                <span className="cart-section-empty__text__login">
                  {' '}
                  Go shopping or <span onClick={() => setIsAuth(true)}> log in </span>{' '}
                </span>
              ) : (
                <span className="cart-section-empty__text__login">
                  {retailCount &&
                  wholesaleCount &&
                  retailCount.priceCategoryCount > 0 &&
                  wholesaleCount.priceCategoryCount <= 0 ? (
                    <>
                      Go shopping{' '}
                      <a
                        href={
                          retailCount.machineCategoryCount
                            ? `${routesClient.productsRetail.path}/${retailCount.machineCategoryCount}`
                            : routesClient.productsRetail.path
                        }
                      >
                        retail
                      </a>
                    </>
                  ) : retailCount &&
                    wholesaleCount &&
                    wholesaleCount.priceCategoryCount > 0 &&
                    retailCount.priceCategoryCount <= 0 ? (
                    <>
                      Go shopping{' '}
                      <a
                        href={
                          wholesaleCount.machineCategoryCount
                            ? `${routesClient.productsWholesale.path}/${wholesaleCount.machineCategoryCount}`
                            : routesClient.productsWholesale.path
                        }
                      >
                        wholesale
                      </a>
                    </>
                  ) : (
                    'No items available for shopping'
                  )}
                </span>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="cart-section-content">
              {width <= 1024 ? (
                <>
                  <div className="cart-section-content__list_1024">
                    <ul>
                      {userCart.pc.map((item: any, index: number) => (
                        <ListTable key={index} row={item} />
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                <>
                  <table className="cart-section-content__table">
                    <thead className="cart-section-content__table__thead">
                      <tr>
                        <th className="model">Model</th>
                        <th className="status">Product status</th>
                        <th className="cost">Cost</th>
                        <th className="quantity">Quantity</th>
                        <th colSpan={2} className="total">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody className="cart-section-content__table__tbody">
                      {userCart.pc.map((item: any, index: number) => (
                        <TableRow key={index} row={item} />
                      ))}
                    </tbody>
                  </table>
                </>
              )}

              <div className="cart-section-content__under">
                <div className="cart-section-content__total-price">
                  <div className="cart-section-content__total-price__seller">
                    Seller: AsicXchange
                  </div>
                  <div className="cart-section-content__total-price__price">
                    <span className="cart-section-content__total-price__text">Total price:</span>
                    <span className="cart-section-content__total-price__value">
                      ${formatToTwoPlaces(totalPrice)}
                    </span>
                  </div>
                </div>
                <div className="cart-section-content__buttons">
                  <div className="cart-section-content__buttons__clear-cart">
                    <button onClick={deleteAllRow}>Clear List</button>
                  </div>
                  <div className="cart-section-content__buttons__checkout">
                    <div className="cart-section-content__buttons__checkout__terms">
                      <input
                        type="checkbox"
                        id="term"
                        defaultChecked={true}
                        onChange={changeChecboxTerms}
                      />
                      <label htmlFor="term" className="term-label">
                        I agree with the terms of <Link to="/public-offer">the Public Offer</Link>{' '}
                        and <Link to="/user-agreement">the User Agreement</Link>
                      </label>
                    </div>
                    <div className="cart-section-content__buttons__checkout__button-message">
                      <button
                        className="cart-section-content__buttons__checkout__button"
                        onClick={createOrder}
                        disabled={isUserAgreementChecked}
                      >
                        Checkout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Cart;
