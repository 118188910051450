import './Proccesing.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import InformationIcon from '../../../../general/assets/svg/InformationIcon';
import Preloader, { preloaderStyles } from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { useResize } from '../../../../general/hooks/useResize';
import useScrollTo from '../../../../general/hooks/useScrollTo';
import useScrollToBottom from '../../../../general/hooks/useScrollToBottom';
import { queryKeys } from '../../../../general/queryKeys';
import { clearCart } from '../../../../general/redux/reducers/ClientCart-Reducer';
import {
  billingState,
  deliveryMethodsEnum,
  initUser,
  paymentServicesEnum,
  setFieldsErrors,
  setIsBillingTheSame,
  setPaymntService,
  setReatesResponce,
  setReciept,
  setShippingStoresLocationTransactionId,
  setTax,
} from '../../../../general/redux/reducers/ClientCartProcessing-Reducer';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import { RootState } from '../../../../general/redux/store';
import { routesClient } from '../../../../general/routes';
import createClientService from '../../../../general/services/client';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import createShippingService from '../../../../general/services/shipping';
import createStaticPaymentInfosService from '../../../../general/services/staticPayments';
import { Rates, TransactionProcessAddress, UserCartForOrde } from '../../../../general/types/types';
import {
  errorMessages,
  formatToTwoPlaces,
  regexp,
  validate,
  validateFields,
} from '../../../../general/utils/Validations';
import { removeDataForage } from '../../../../localforage';
import Order from './Order';
import {
  getAddress,
  getAddressFrom,
  getBodyForOrder,
  getInfoForRates,
  getInfoForTransaction,
  getInputs,
} from './ProcessingMethods';
import TaxToolTrip from './TaxToolTrip';

const Proccesing: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clientCartProcessing = useSelector((state: RootState) => state.clientCartProcessing);
  const userId = useSelector((state: RootState) => state.clps.cp2);
  const userCart: UserCartForOrde = useSelector((state: RootState) => state.pc);
  const scrollTo = useScrollTo();
  const resize = useResize();
  const [isHoveredTax, setIsHoveredTax] = useState<boolean>();

  //const paymentCurrency = useSelector((state: RootState) => state.clientCartProcessing.paymentCurrency);

  const [subtotal, setSubtotal] = useState(0);
  const [isForUsed, setIsForUsed] = useState<boolean | undefined>();
  //const [isCompleat, setIsCompleat] = useState(false);
  const [isFetch, setIsFetch] = useState(false);

  //#region mutations and queryes
  const { mutateAsync: createShippingTransaction } = useCustomMutation(
    createShippingService(clientInstance).CreateTransaction,
  );

  const { mutateAsync: getRates } = useCustomMutation(
    createShippingService(clientInstance).GetShippingRates,
  );

  const { mutateAsync: validateShippingAddress } = useCustomMutation(
    createShippingService(clientInstance).ValidateAddress,
  );

  // const { mutateAsync: createPayment } = useCustomMutation(
  //   createPaymentService(clientInstance).createTransaction,
  // );

  const { mutateAsync: createPickUpTransaction } = useCustomMutation(
    createShippingService(clientInstance).createPickUpTransaction,
    undefined,
    {
      onSuccess(data: any) {
        dispatch(setShippingStoresLocationTransactionId(data.id));
      },
    },
  );

  const { mutate: newOrder } = useCustomMutation(
    createClientService(clientInstance).postClientOrder,
    undefined,
    {
      onSuccess: async () => {
        dispatch(clearCart());
        dispatch(setShippingStoresLocationTransactionId(null));
        await removeDataForage('contactsMethod');
        //setIsCompleat(true);

        navigate(routesClient.profileOrders.path);
        setIsFetch(false);
        dispatch(setTax(0));
      },
    },
  );

  const { data: user } = useCustomQuery(
    queryKeys.USER_INFO,
    () => createClientService(clientInstance).getClientInfo(userId),
    undefined,
    () => {
      navigate('/profile');
    },
    {
      enabled: !!userId,
    },
  );

  const { data: shippingCostData } = useCustomQuery(
    queryKeys.SHIPPING_COST,
    () => createStaticPaymentInfosService(clientInstance).getInfos(),
    (data: any) => ({ lastId: data[0].id, shippingCost: data[0].shippingCost }),
    undefined,
    {
      enabled: !!userId,
    },
  );
  //#endregion

  //#region effects
  useEffect(() => {
    if (!userId) {
      navigate(routesClient.home.path);
    }

    if (userCart.count <= 0) {
      navigate(routesClient.home.path + routesClient.productsRetail.path);
    } else {
      const storedValues = sessionStorage.getItem('shipping-fields');
      const values: any[] = storedValues ? JSON.parse(storedValues) : [];

      if (values.length <= 0) {
        navigate(routesClient.cartInformation.path);
      } else {
        let allFieldsInitialized = true;
        values.forEach((obj) => {
          if (
            !obj.value &&
            !['company', 'street2', 'street3', 'streetNumber'].includes(obj.field)
          ) {
            allFieldsInitialized = false;
          }
        });

        if (!allFieldsInitialized) {
          navigate(routesClient.cartInformation.path);
        }
      }
    }
  }, [navigate, userId, userCart]);

  useEffect(() => {
    user && dispatch(initUser(user));
    setIsForUsed(userCart.pc.some((element: any) => element.p.condition.toLowerCase() === 'used'));
  }, [dispatch, userCart.pc, user]);

  useEffect(() => {
    return () => {
      dispatch(setFieldsErrors([]));
      dispatch(setIsBillingTheSame(billingState.DEFAULT));
      dispatch(setReciept(null));
      dispatch(setReatesResponce(null));
      dispatch(setPaymntService(paymentServicesEnum.DEFAULT));
    };
  }, [dispatch]);

  useEffect(() => {
    let total = 0;
    userCart.pc.forEach((order: any) => {
      total += order.p.price * order.c;
    });
    setSubtotal(total);
  }, [userCart.pc]);
  //#endregion

  const scrolToTop = () => {
    scrollTo('top');
  };

  const validateAddresses = async () => {
    if (
      clientCartProcessing.deliveryMethod === deliveryMethodsEnum.SELF_PICKUP &&
      clientCartProcessing.shippingStoresLocationAddress &&
      clientCartProcessing.shippingStoresLocationAddress.length <= 0
    ) {
      dispatch(setType(types.WARNING));
      dispatch(
        setInfo({
          n: types.WARNING,
          r: {
            s: 'YOu have to choose at least one store location',
          },
        }),
      );

      return;
    }

    if (
      clientCartProcessing.isBillingTheSame === billingState.DEFAULT &&
      clientCartProcessing.deliveryMethod === deliveryMethodsEnum.SHIPPING
    ) {
      const anchorElement = document.getElementById('billing-question');
      if (anchorElement) {
        anchorElement.classList.add('shaking-text');
        scrollTo('billing-question');

        const removeClass = () => {
          anchorElement.classList.remove('shaking-text');
          anchorElement.removeEventListener('animationend', removeClass);
        };

        anchorElement.addEventListener('animationend', removeClass);
      }
      return;
    }

    setIsFetch(true);

    //#region validating fields
    if (
      clientCartProcessing.isBillingTheSame === billingState.DEFAULT &&
      clientCartProcessing.deliveryMethod === deliveryMethodsEnum.SHIPPING
    ) {
      const question = document.querySelector('#billing-question') as HTMLElement;
      question.style.color = 'red';
      setIsFetch(false);
      return;
    }

    const required: boolean[] = [];

    if (clientCartProcessing.deliveryMethod === deliveryMethodsEnum.SHIPPING) {
      const shippingInputs = getInputs(
        'shipping-fields',
        clientCartProcessing.isOptionalLocationVisible,
      );
      try {
        sessionStorage.setItem(
          'shipping-fields',
          JSON.stringify(
            shippingInputs.map((element) => ({
              field: element.errorField,
              value: (element.input as HTMLInputElement).value,
            })),
          ),
        );
        dispatch(setFieldsErrors(validateFields(shippingInputs, required)));
        scrolToTop();
      } catch {
        dispatch(setFieldsErrors(JSON.parse(sessionStorage.getItem('shipping-fields') || '[]')));
        scrolToTop();
      }

      sessionStorage.setItem(
        'billing-fields',
        JSON.stringify(
          shippingInputs.map((element) => ({
            field: element.errorField,
            value: (element.input as HTMLInputElement).value,
          })),
        ),
      );
    }

    if (
      clientCartProcessing.deliveryMethod === deliveryMethodsEnum.SELF_PICKUP ||
      clientCartProcessing.isBillingTheSame === billingState.NOT_SAME
    ) {
      const billingInputs = getInputs(
        'billing-fields',
        clientCartProcessing.isOptionLocationBillingVisible,
      );
      const errors = validateFields(billingInputs, required);
      try {
        errors.length <= 0 &&
          sessionStorage.setItem(
            'billing-fields',
            JSON.stringify(
              billingInputs.map((element) => ({
                field: element.errorField,
                value: (element.input as HTMLInputElement).value,
              })),
            ),
          );
        dispatch(setFieldsErrors(errors));
        scrolToTop();
      } catch {
        errors.length <= 0 &&
          setFieldsErrors(JSON.parse(sessionStorage.getItem('billing-fields') || '[]'));
        scrolToTop();
      }
    }
    //#endregion

    if (required.includes(false)) {
      setIsFetch(false);
      return;
    } else {
      const billingAddress: any =
        clientCartProcessing.isBillingTheSame === billingState.NOT_SAME ||
        clientCartProcessing.deliveryMethod === deliveryMethodsEnum.SELF_PICKUP
          ? getAddress(clientCartProcessing.isOptionLocationBillingVisible, 'billing-fields')
          : undefined;

      if (clientCartProcessing.deliveryMethod === deliveryMethodsEnum.SHIPPING) {
        const addressTo: any = getAddress(
          clientCartProcessing.isOptionalLocationVisible,
          'shipping-fields',
        );
        const companyAddress: any = await getAddressFrom();

        //#region validating adresses
        addressTo.isForClient = true; // skip some errors from shippo service
        const addressToResp: any = await validateShippingAddress(addressTo);

        if (addressToResp.name === 'AxiosError') {
          setIsFetch(false);
          dispatch(setType(types.ERROR));
          dispatch(
            setInfo({
              n: addressToResp.message,
              r: addressToResp.hasOwnProperty('response')
                ? {
                    d: addressToResp.response.data,
                    s: addressToResp.response.statusText,
                  }
                : '',
            }),
          );
          return;
        }

        const getValidationErrors = () => {
          const messages = addressToResp.messages;
          return messages
            .map((message: any, index: number) => `${index + 1}. ${message.text}`)
            .join('\n');
        };

        if (!addressToResp.isValid || addressToResp.isValid === null) {
          setIsFetch(false);
          const errors =
            addressToResp?.messages.length > 0
              ? getValidationErrors()
              : 'Address not correct. Please check its correctness';
          dispatch(setType(types.ERROR));
          dispatch(
            setInfo({
              n: types.ERROR,
              r: { s: errors },
            }),
          );
          return;
        }
        //#endregion

        navigate('shipping');
        createRatesForOrder(companyAddress, addressTo, billingAddress);
      } else if (clientCartProcessing.deliveryMethod === deliveryMethodsEnum.SELF_PICKUP) {
        const locationsIds = clientCartProcessing.shippingStoresLocationAddress.map(
          (location: any) => location.id,
        );

        navigate('shipping');
        createPickUpTransaction({ locations: locationsIds, billingAddress: billingAddress });
        setIsFetch(false);
      }
    }
  };

  const createRatesForOrder = async (
    companyAddress: TransactionProcessAddress,
    addressTo: TransactionProcessAddress,
    billingAddress?: TransactionProcessAddress,
  ) => {
    const toReates = await getInfoForRates(companyAddress, addressTo, billingAddress, userCart);
    const rates: any = await getRates(toReates);

    dispatch(setReatesResponce(rates));

    setIsFetch(false);
  };

  const setContactMethod = async () => {
    if (
      !clientCartProcessing.contactMethod ||
      clientCartProcessing.contactMethod.value.trim() === ''
    ) {
      dispatch(setType(types.WARNING));
      dispatch(
        setInfo({
          n: types.WARNING,
          r: {
            s: 'Please provide your preffered contact method for order updates',
          },
        }),
      );

      const element = document.getElementById('contacts');
      scrollTo('contacts');

      if (element) {
        element.style.border = '1px solid #DB6484';

        setTimeout(() => {
          element.style.border = '';
        }, 2000);
      }
      setIsFetch(false);
      return;
    } else {
      const { name, value } = clientCartProcessing.contactMethod;

      // Сопоставляем регулярные выражения и сообщения об ошибках
      const validationRules: Record<string, { regex: RegExp; errorMessage: string }> = {
        phone: { regex: regexp.phone_regexp, errorMessage: errorMessages[4] },
        email: { regex: regexp.email_regexp, errorMessage: errorMessages[4] },
        whatsapp: {
          regex: regexp.whatsapp_regexp,
          errorMessage: errorMessages[4],
        },
        telegram: {
          regex: regexp.telegram_regexp,
          errorMessage: errorMessages[4],
        },
      };

      try {
        const { regex, errorMessage } = validationRules[name] || {};
        if (regex) {
          validate(value, regex, errorMessage);
        }
      } catch (error) {
        const element = document.getElementById(name);
        if (element) {
          element.style.background = '#512531';
          element.style.color = '#DB6484';
          element.style.border = '1px solid #DB6484';

          setTimeout(() => {
            element.style.border = '';
            element.style.background = '';
            element.style.color = '';
          }, 2000);
        }
      }
    }

    navigate('shipping/payment');
  };

  const createOrder = async () => {
    if (clientCartProcessing.paymentService === paymentServicesEnum.DEFAULT) {
      dispatch(setType(types.WARNING));
      dispatch(
        setInfo({
          n: types.WARNING,
          r: { s: 'You have to choose a payment method' },
        }),
      );

      scrollTo('payment-options');

      return;
    }

    if (
      clientCartProcessing.paymentService === paymentServicesEnum.BANK_TRANSACTION &&
      !clientCartProcessing.reciept &&
      !clientCartProcessing.isUploadRecieptLater
    ) {
      dispatch(setType(types.WARNING));
      dispatch(
        setInfo({
          n: types.WARNING,
          r: { s: 'You have to check "uplaod later"' },
        }),
      );

      const anchorElement = document.getElementById('upload-later-reciept-container');
      if (anchorElement) {
        anchorElement.classList.add('shaking-text');
        scrollTo('upload-later-reciept-container');

        const removeClass = () => {
          anchorElement.classList.remove('shaking-text');
          anchorElement.removeEventListener('animationend', removeClass);
        };

        anchorElement.addEventListener('animationend', removeClass);
      }

      return;
    }

    if (
      clientCartProcessing.paymentService === paymentServicesEnum.STABLE_COIN &&
      !clientCartProcessing.reciept
    ) {
      dispatch(setType(types.WARNING));
      dispatch(
        setInfo({
          n: types.WARNING,
          r: { s: 'You have to note a correct transaction id or link' },
        }),
      );

      scrollTo('stable-coins-reciept');

      return;
    }

    setIsFetch(true);

    if (
      clientCartProcessing.deliveryMethod === deliveryMethodsEnum.SHIPPING &&
      !clientCartProcessing.ratesResponce
    ) {
      navigate(routesClient.cartInformation.path);
      setIsFetch(false);
      return;
    }

    const transaction: any =
      clientCartProcessing.shippingStoresLocationTransactionId === null
        ? await createShippingTransaction(
            getInfoForTransaction(
              clientCartProcessing.ratesResponce as Rates,
              undefined,
              isForUsed,
            ),
          )
        : clientCartProcessing.shippingStoresLocationTransactionId;

    const body: any = getBodyForOrder(
      userCart,
      userId,
      transaction,
      {
        name: clientCartProcessing.contactMethod?.name || '',
        value: clientCartProcessing.contactMethod?.value || '',
      },
      shippingCostData?.shippingCost,
    );

    if (
      clientCartProcessing.paymentService === paymentServicesEnum.BANK_TRANSACTION &&
      clientCartProcessing.reciept
    ) {
      body.recieptId = clientCartProcessing.reciept;
    }

    // if (clientCartProcessing.paymentService === paymentServicesEnum.COIN_PAL) {
    //   const createdTransaction: any = await createPaymentTransaction();

    //   const goToPartner = (paymentUrl: string) => {
    //     window.open(paymentUrl, '_blank');
    //   };
    //   goToPartner(createdTransaction.nextStepContent);

    //   body.paymentRequestId = createdTransaction.requestId;
    //   body.orderNo = createdTransaction.orderNo;
    //   body.reference = createdTransaction.reference;
    // }

    if (
      clientCartProcessing.paymentService === paymentServicesEnum.STABLE_COIN &&
      clientCartProcessing.reciept
    ) {
      body.stabelCoinReciept = clientCartProcessing.reciept;
    }

    if (clientCartProcessing.deliveryMethod === deliveryMethodsEnum.SELF_PICKUP) {
      body.isPickUp = true;
    } else {
      body.isPickUp = false;
    }

    body.isUploadLaterBankTransactionReciept = clientCartProcessing.isUploadRecieptLater;
    body.tax = clientCartProcessing.tax;

    newOrder(body);
  };

  // const createPaymentTransaction = async () => {
  //   const bodyforPayment = {
  //     createPaymentTransaction: {
  //       orderAmount: getTotal().toString(),
  //       orderCurrency: paymentCurrency || 'USD',
  //     },
  //   };

  //   const resp = await createPayment(bodyforPayment);
  //   return resp;
  // };

  const handleClick = () => {
    const sections = window.location.href.split('/');

    if (sections.includes('payment')) {
      createOrder();
    } else if (sections.includes('shipping')) {
      setContactMethod();
    } else if (sections.includes('information')) {
      validateAddresses();
    }
  };

  //#region fixed right panel
  const { containerRef, isFixed, isBottom, setIsBottom, setIsFixed } = useScrollToBottom();
  const [itIsMoreContent, setIsMoreContent] = useState(false);
  const [isLessContent, setIsLessContent] = useState(false);

  useEffect(() => {
    setIsMoreContent(clientCartProcessing.isBillingTheSame === billingState.NOT_SAME);
    setIsLessContent(clientCartProcessing.isBillingTheSame === billingState.SAME);

    setTimeout(() => {
      setIsMoreContent(false);
    }, 100);

    setTimeout(() => {
      setIsLessContent(false);
    }, 100);
  }, [clientCartProcessing.isBillingTheSame]);

  useEffect(() => {
    if (itIsMoreContent && isBottom) {
      setIsBottom(false);
      setIsFixed(true);
    }

    if (isLessContent && isFixed) {
      setIsBottom(true);
      setIsFixed(false);
    }
  }, [itIsMoreContent, isLessContent, isFixed, isBottom, setIsBottom, setIsFixed]);
  //#endregion

  //#region price operations
  const getTotal = () => {
    const totalWithoutTax = parseFloat(getTotalWithoutTax());

    const sections = window.location.href.split('/');
    let tax = 0;

    if (sections.includes('shipping')) {
      tax = clientCartProcessing.tax;
    }

    return (totalWithoutTax + tax).toFixed(2);
  };

  const getShippingCost = useCallback(() => {
    const amount = userCart.pc.reduce((total: any, order: any) => total + order.c, 0);
    return amount * (shippingCostData?.shippingCost || 0);
  }, [shippingCostData?.shippingCost, userCart.pc]);

  const getTotalWithoutTax = useCallback(() => {
    const subtotal = userCart.pc.reduce(
      (total: any, order: any) => total + order.p.price * order.c,
      0,
    );

    const sections = window.location.href.split('/');

    if (sections.includes('shipping')) {
      return subtotal + getShippingCost();
    } else {
      return subtotal;
    }
  }, [getShippingCost, userCart.pc]);

  useEffect(() => {
    const calculateTax = () => {
      const storedValues = sessionStorage.getItem('shipping-fields');
      const values: any[] = storedValues ? JSON.parse(storedValues) : [];

      const country: string = Array.isArray(values)
        ? values.find((obj) => obj.field === 'country')?.value || ''
        : '';
      const state: string = Array.isArray(values)
        ? values.find((obj) => obj.field === 'state')?.value || ''
        : '';

      const countryVariants = ['canada', 'ca', 'can'];
      const quebecVariants = ['quebec', 'qc', 'québec'];
      const normalizedCountry = country.toLowerCase();
      const normalizedState = state.toLowerCase();

      let tax;

      if (countryVariants.includes(normalizedCountry) && quebecVariants.includes(normalizedState)) {
        const TPS = getTotalWithoutTax() * 0.05;
        const TVQ = getTotalWithoutTax() * 0.09975;
        tax = TPS + TVQ;
      } else if (countryVariants.includes(normalizedCountry)) {
        tax = getTotalWithoutTax() * 0.05;
      } else {
        tax = 0;
      }

      dispatch(setTax(tax));

      return tax;
    };

    if (userCart.count > 0) {
      calculateTax();
    }
  }, [dispatch, getTotalWithoutTax, userCart, location]);
  //#endregion

  const isShowTax = useCallback(() => {
    const storedValues = sessionStorage.getItem('shipping-fields');
    const values: any[] = storedValues ? JSON.parse(storedValues) : [];

    if (values.length <= 0) {
      return false;
    }

    const country: string = values.find((obj) => obj.field === 'country').value;

    const countryVariants = ['canada', 'ca', 'can'];
    const normalizedCountry = country.toLocaleLowerCase();

    const urlParts = window.location.href.split('/');

    if (urlParts.includes('shipping')) {
      if (urlParts.includes('payment') && !countryVariants.includes(normalizedCountry)) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }, []);

  const getShippingValues = () => {
    const values: any[] = JSON.parse(sessionStorage.getItem('shipping-fields') || '{}');
    const country: string = values.find((obj) => obj.field === 'country').value;
    const state: string = values.find((obj) => obj.field === 'state').value;

    return {
      country: country,
      state: state,
    };
  };

  return (
    <>
      {/* {isCompleat && <OrderCompleat isActive={setIsCompleat} />} */}
      <div className="cart-section page-container">
        <div className="cart-section-top">
          <div className="cart-section-top__title">CART</div>
        </div>
        <div id="top" className="cart-proccesing-section">
          <div className="cart-proccesing-active-section">
            <Outlet />
          </div>

          <div className="cart-proccesing-inactive-section" ref={containerRef}>
            <div className={`sticky ${isFixed ? 'fixed' : ''} ${isBottom ? 'bottom' : ''}`}>
              <div className="cart-proccesing-products">
                <div className="prods">
                  {userCart.pc?.map((order: any, index: any) => (
                    <React.Fragment key={index}>
                      <Order order={order} />
                    </React.Fragment>
                  ))}
                </div>
                {window.location.href.includes('shipping') && (
                  <>
                    <hr />
                    <div className="subtotal-price">
                      <span>Subtotal</span>
                      <span className="dots"></span>
                      <span>${formatToTwoPlaces(subtotal)}</span>
                    </div>
                    {!isForUsed && (
                      <div className="shipping-price">
                        <span>Shipping</span>
                        <span className="dots"></span>
                        <span>${formatToTwoPlaces(getShippingCost())}</span>
                      </div>
                    )}
                    {userCart.count > 0 && isShowTax() && (
                      <>
                        <div className="tax">
                          <span>Tax</span>
                          <div
                            className="tax-info-btn"
                            style={{ display: 'flex', marginLeft: '5px' }}
                            onMouseEnter={() => setIsHoveredTax(true)}
                            onMouseLeave={() => setIsHoveredTax(false)}
                            onClick={() => setIsHoveredTax(!isHoveredTax)}
                          >
                            <InformationIcon />
                          </div>
                          <span className="dots"></span>
                          <span>${formatToTwoPlaces(clientCartProcessing.tax)}</span>
                          {resize.width >= 768 && (
                            <TaxToolTrip
                              style={{
                                top: isFixed || isBottom ? '150%' : '',
                              }}
                              selectedCountry={getShippingValues().country}
                              selectedProvince={getShippingValues().state}
                              className={`tax-tooltip  ${(isFixed || isBottom) && 'tax-tooltip-reverted'}`}
                            />
                          )}
                        </div>
                        {resize.width < 768 && (
                          <TaxToolTrip
                            selectedCountry={getShippingValues().country}
                            selectedProvince={getShippingValues().state}
                            className={`tax-tooltip ${isHoveredTax ? 'hovered' : ''}`}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
                <hr />
                <div className="total">
                  <span>Total amount</span>
                  <span>{getTotal()}</span>
                </div>
              </div>
              <div className="cart-proccesing-delivary-info">
                <p>Order Details Confirmation</p>
                <p>
                  We will contact you to confirm the shipping and payment details after we receive
                  your order. This will help us ensure the accuracy and timelines of your delivery
                </p>
              </div>
              <div className="cart-proccesing-button">
                <button className="rates" onClick={handleClick}>
                  {!isFetch && !window.location.href.includes('shipping') ? (
                    'Confirm'
                  ) : !isFetch && window.location.href.includes('shipping') ? (
                    'Check out'
                  ) : (
                    <Preloader style={preloaderStyles.DARK} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Proccesing;
