import { AxiosInstance } from 'axios';

interface MailingService {
  createNewsLetter: (body: { title: string; content: string }) => Promise<any>;
  updateNewsLetter: (body: {
    id: string;
    title: string;
    content: string;
    timeToPost: string;
  }) => Promise<any>;
  getNewsLetter: (page?: number, pageSize?: number) => Promise<any>;
  sendNewsLetter: (id: string) => Promise<any>;
  postImage: (image: File) => Promise<any>;
}

const createMailingService = (instance: AxiosInstance): MailingService => ({
  async postImage(image: File) {
    const formData = new FormData();
    formData.append('image', image);

    const data = {
      image: image,
    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.post('/Newsletter/contentImage', data, {
        headers,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async sendNewsLetter(id: string) {
    try {
      const responce = await instance.post('/Newsletter/sendToSubscribers', {
        newsletterId: id,
      });
      return responce;
    } catch (error) {
      return error;
    }
  },

  async createNewsLetter(body: { title: string; content: string }) {
    try {
      const responce = await instance.post(`/Newsletter`, {
        title: body.title,
        content: body.content,
      });
      return responce;
    } catch (error) {
      return error;
    }
  },

  async updateNewsLetter(body: { id: string; title: string; content: string; timeToPost: string }) {
    try {
      const responce = await instance.put(`/Newsletter`, {
        id: body.id,
        title: body.title,
        content: body.content,
        timeToPost: body.timeToPost,
      });
      return responce;
    } catch (error) {
      return error;
    }
  },

  async getNewsLetter(page = 1, pageSize = 10) {
    try {
      const responce = await instance.get(`/Newsletter/all?Page=${page}&PageSize=${pageSize}`);
      return responce;
    } catch (error) {
      return error;
    }
  },
});

export default createMailingService;
