import './FrequentlyAskedQuestion.scss';

import React from 'react';

import { queryClient } from '../../../..';
import CloseBig from '../../../../general/assets/svg/CloseBig';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useKey, { keys } from '../../../../general/hooks/useKey';
import { queryKeys } from '../../../../general/queryKeys';
import createFandQService from '../../../../general/services/FandQ';
import { adminInstance } from '../../../../general/services/main/axiosInstances';

interface ModalQuestionAndAnswerProps {
  isOpen: (open: boolean) => void;
  faq?: {
    id?: string;
    gropId?: string;
    question?: string;
    answer?: string;
  };
}

export const ModalQuestionAndAnswer: React.FC<ModalQuestionAndAnswerProps> = ({ isOpen, faq }) => {
  const { mutate: postFAQ, isLoading: posting } = useCustomMutation(
    createFandQService(adminInstance).postFAQ,
    undefined,
    {
      onSuccess() {
        queryClient.refetchQueries(queryKeys.ADMIN_F_GROUP);
      },
    },
  );

  const { mutate: putFAQ, isLoading: updating } = useCustomMutation(
    createFandQService(adminInstance).putFAQ,
    undefined,
    {
      onSuccess() {
        queryClient.refetchQueries(queryKeys.ADMIN_F_GROUP);
      },
    },
  );

  useKey((event) => {
    if (event.key === keys.ESCAPE) {
      isOpen && isOpen(false);
      closeModal();
    }
  });

  const closeModal = () => {
    isOpen(false);
  };

  const setDefaultBorder = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.style.outline = 'none';
  };

  const addFAQ = async () => {
    const inputsBlock = document.querySelector('.modal-window-action__modal__faq') as HTMLElement;
    const input = inputsBlock.querySelector('input') as HTMLInputElement;
    const textarea = inputsBlock.querySelector('textarea') as HTMLTextAreaElement;

    const required: boolean[] = [];

    if (input.value.trim() === '') {
      input.style.outline = '1px solid red';
      required.push(false);
    }

    if (textarea.value.trim() === '') {
      textarea.style.outline = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      return;
    } else {
      postFAQ({
        groupId: faq?.gropId || '',
        question: input.value,
        answer: textarea.value,
      });
    }
    closeModal();
  };

  const updateFAQ = async () => {
    const inputsBlock = document.querySelector('.modal-window-action__modal__faq') as HTMLElement;
    const input = inputsBlock.querySelector('input') as HTMLInputElement;
    const textarea = inputsBlock.querySelector('textarea') as HTMLTextAreaElement;

    const required: boolean[] = [];

    if (input.value.trim() === '') {
      input.style.outline = '1px solid red';
      required.push(false);
    }

    if (textarea.value.trim() === '') {
      textarea.style.outline = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      return;
    } else {
      putFAQ({
        id: faq?.id || '',
        question: input.value,
        answer: textarea.value,
      });
    }
    closeModal();
  };

  return (
    <div
      className="modal-window-action"
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <div className="modal-window-action__overlay" onClick={closeModal} />
      <div className="modal-window-action__modal__home" style={{ width: '700px' }}>
        {updating && posting && (
          <div className="modal-window-action__modal__home__loading">
            <Preloader />
          </div>
        )}
        <div className="modal-window-action__modal__title">
          <span>FAQ</span>
          <button onClick={closeModal}>
            <CloseBig />
          </button>
        </div>
        <div className="modal-window-action__modal__faq">
          <input
            onFocus={setDefaultBorder}
            type="text"
            placeholder="question"
            defaultValue={faq?.question || ''}
          />
          <textarea
            onFocus={setDefaultBorder}
            placeholder="answer"
            defaultValue={faq?.answer || ''}
          />
        </div>
        <div className="modal-window-action__modal__button">
          <button
            className="section-btn-gradient"
            onClick={faq?.answer === undefined ? addFAQ : updateFAQ}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
