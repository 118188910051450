import './AdminContacts.scss';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Preloader from '../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import useScrollTo from '../../../general/hooks/useScrollTo';
import { queryKeys } from '../../../general/queryKeys';
import { setInfo, setType, types } from '../../../general/redux/reducers/Error-Reducer';
import createContactsService from '../../../general/services/contacts';
import { adminInstance } from '../../../general/services/main/axiosInstances';
import createShippingService from '../../../general/services/shipping';
import {
  errorMessages,
  regexp,
  validateFields,
  validatePhoneNumber,
} from '../../../general/utils/Validations';

// Define types for fields
interface Field {
  name: string;
  value: string;
}

// Utility function to initialize a field
const initializeField = (name: string, value = ''): Field => ({ name, value });

// Utility functions to initialize various fields
const initializeWebContacts = (response: any = {}): Field[] => [
  initializeField('Address', response.addressWeb),
  initializeField('Phone', response.phoneWeb),
  initializeField('Email', response.emailWeb),
];

const initializeLinks = (response: any = {}): Field[] => [
  initializeField('Telegram', response.telegramLink),
  initializeField('Facebook', response.facebookLink),
  initializeField('LinkedIn', response.linkedinLink),
  initializeField('Instagram', response.instagramLink),
  initializeField('X (Twitter)', response.xLink),
  initializeField('WhatsApp', response.whatsappLink),
];

const initializeAddress = (response: any = {}): Field[] => [
  initializeField('Name*', response.name),
  initializeField('Company*', response.company),
  initializeField('Country*', response.country),
  initializeField('City*', response.city),
  initializeField('State*', response.state),
  initializeField('Street number', response.streetNumber),
  initializeField('Zip*', response.zip),
  initializeField('Street 1*', response.street1),
  initializeField('Street 2', response.street2),
  initializeField('Street 3', response.street3),
  initializeField('Phone*', response.phone),
  initializeField('Email*', response.email),
  initializeField('Additional info', response.metadata),
];

// Change handlers
const handleChange = (
  index: number,
  value: string,
  setState: React.Dispatch<React.SetStateAction<Field[]>>,
) => {
  setState((prevState) => {
    const updatedState = [...prevState];
    updatedState[index].value = value;
    return updatedState;
  });
};

const handleChangeAddress = (
  index: number,
  value: string,
  setAddress: React.Dispatch<React.SetStateAction<Field[]>>,
) => handleChange(index, value, setAddress);
const handleChangeLinks = (
  index: number,
  value: string,
  setLinks: React.Dispatch<React.SetStateAction<Field[]>>,
) => handleChange(index, value, setLinks);

// Check for non-empty shipping fields
const hasNonEmptyShippingField = (inputs: HTMLInputElement[]) =>
  [
    inputs[9],
    inputs[11],
    inputs[12],
    inputs[13],
    inputs[14],
    inputs[15],
    inputs[16],
    inputs[17],
    inputs[18],
    inputs[19],
    inputs[20],
    inputs[21],
  ].some((input) => input.value.trim() !== '');

// Validate inputs
const validateInputs = (inputs: HTMLInputElement[]) => {
  const required: boolean[] = [];
  const fieldsConfig = [
    {
      input: inputs[12],
      regex: regexp.string,
      errorField: 'name',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[13],
      regex: regexp.string,
      errorField: 'company',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[18],
      regex: regexp.string,
      errorField: 'street1',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[19],
      regex: regexp.string,
      errorField: 'street2',
      errorMessage: errorMessages[0],
      optional: true,
    },
    {
      input: inputs[20],
      regex: regexp.string,
      errorField: 'street3',
      errorMessage: errorMessages[0],
      optional: true,
    },
    {
      input: inputs[16],
      regex: regexp.string,
      errorField: 'streetNumber',
      errorMessage: errorMessages[0],
      optional: true,
    },
    {
      input: inputs[9],
      regex: regexp.string,
      errorField: 'country',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[11],
      regex: regexp.string,
      errorField: 'state',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[10],
      regex: regexp.string,
      errorField: 'city',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[17],
      regex: regexp.numbers,
      errorField: 'zip',
      errorMessage: errorMessages[0],
    },
    {
      input: inputs[14],
      validateFunc: validatePhoneNumber,
      errorField: 'phone',
    },
    {
      input: inputs[15],
      regex: regexp.email_regexp,
      errorField: 'email',
      errorMessage: errorMessages[1],
    },
  ];
  validateFields(fieldsConfig, required);
  return !required.includes(false);
};

// Validate address with external service (e.g., Shippo)
const validateAddressWithShippo = async (
  address: any,
  dispatch: any,
  setIsPreloader: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { data } = await createShippingService(adminInstance).ValidateAddress(address);
  const addressFromResp = data;

  if (addressFromResp.name === 'AxiosError') {
    setIsPreloader(false);
    dispatch(setType(types.ERROR));
    dispatch(
      setInfo({
        n: addressFromResp.message,
        r: addressFromResp.hasOwnProperty('response')
          ? {
              d: addressFromResp.response.data,
              s: addressFromResp.response.statusText,
            }
          : '',
      }),
    );
    return false;
  }

  const getValidationErrors = () =>
    addressFromResp.messages
      .map((message: any, index: number) => `${index + 1}. ${message.text}`)
      .join('\n');

  if (!addressFromResp.isValid || addressFromResp.isValid === null) {
    setIsPreloader(false);
    const errors =
      addressFromResp?.messages.length > 0
        ? getValidationErrors()
        : 'Company address not correct. Please check its correctness';
    dispatch(setType(types.ERROR));
    dispatch(setInfo({ n: types.ERROR, r: { s: errors } }));
    return false;
  }
  return true;
};

const AdminContacts: React.FC = () => {
  const dispatch = useDispatch();
  const scrollTo = useScrollTo();
  const [isPreloader, setIsPreloader] = useState<boolean>(false);
  const [webContacts, setWebContacts] = useState<Field[]>(initializeWebContacts());
  const [address, setAddress] = useState<Field[]>(initializeAddress());
  const [links, setLinks] = useState<Field[]>(initializeLinks());

  const { isLoading: loading } = useCustomQuery(
    queryKeys.COMPANY_CONTACTS,
    () => createContactsService(adminInstance).getContacts(),
    undefined,
    undefined,
    {
      onSuccess(data: any) {
        setLinks(initializeLinks(data));
        setWebContacts(initializeWebContacts(data));
        data && setAddress(initializeAddress(data.address));
      },
    },
  );

  const { mutate: postContacts } = useCustomMutation(
    createContactsService(adminInstance).postContacts,
    undefined,
    {
      onSuccess() {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Contacts successfully saved' },
          }),
        );

        scrollTo('main-info');
      },
    },
  );

  const saveContacts = async () => {
    const inputs = document.querySelectorAll<HTMLInputElement>('input');

    const result: any = {
      addressWeb: inputs[0].value.trim(),
      phoneWeb: inputs[1].value.trim(),
      emailWeb: inputs[2].value.trim(),
      telegramLink: inputs[3].value.trim(),
      facebookLink: inputs[4].value.trim(),
      linkedinLink: inputs[5].value.trim(),
      instagramLink: inputs[6].value.trim(),
      xLink: inputs[7].value.trim(),
      whatsappLink: inputs[8].value.trim(),
    };

    if (hasNonEmptyShippingField(Array.from(inputs)) && !validateInputs(Array.from(inputs))) {
      setIsPreloader(false);
      return;
    }

    const addressData = {
      address: {
        name: inputs[12].value.trim(),
        lastName: '',
        company: inputs[13].value.trim(),
        street1: inputs[18].value.trim(),
        street2: inputs[19].value.trim() !== '' ? inputs[19].value.trim() : null,
        street3: inputs[20].value.trim() !== '' ? inputs[20].value.trim() : null,
        streetNumber: inputs[16].value.trim() !== '' ? inputs[16].value.trim() : null,
        city: inputs[10].value.trim(),
        state: inputs[11].value.trim(),
        zip: inputs[17].value.trim(),
        country: inputs[9].value.trim(),
        phone: inputs[14].value.trim(),
        email: inputs[15].value.trim(),
        metadata: inputs[21].value.trim() !== '' ? inputs[21].value.trim() : null,
      },
    };

    result.address = addressData.address;

    if (
      hasNonEmptyShippingField(Array.from(inputs)) &&
      !(await validateAddressWithShippo(addressData, dispatch, setIsPreloader))
    ) {
      setIsPreloader(false);
      return;
    }

    postContacts({ contacts: result });
  };

  const setDefaultBorder = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.style.border = '1px solid #25A8CF';
  };

  return (
    <div className="page-section">
      <div className="page-section__top">
        <div className="page-section__top__title">CONTACTS</div>
      </div>
      <div className="page-section__content">
        {loading && isPreloader && (
          <div className="page-update-overlay">
            <Preloader />
          </div>
        )}
        <div className="page-section__contacts">
          <div id="main-info" className="page-section__contacts__header">
            <span>Website contacts information</span>
          </div>
          <div className="page-section__contacts__body">
            <div className="address-block">
              {webContacts?.map(({ name, value }, index) => (
                <div key={index} className="page-section__contacts__input-block">
                  <label>{name}</label>
                  <input
                    onFocus={setDefaultBorder}
                    type="text"
                    placeholder={name}
                    defaultValue={value}
                    onChange={(e) => handleChangeAddress(index, e.target.value, setWebContacts)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="page-section__contacts">
          <div className="page-section__contacts__header">
            <span>Social information</span>
          </div>
          <div className="page-section__contacts__body">
            {links?.map(({ name, value }, index) => (
              <div key={index} className="page-section__contacts__input-block">
                <label>{name}</label>
                <input
                  onFocus={setDefaultBorder}
                  type="text"
                  placeholder="Link"
                  defaultValue={value}
                  onChange={(e) => handleChangeLinks(index, e.target.value, setLinks)}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="page-section__contacts">
          <div className="page-section__contacts__header">
            <span>Shipping information</span>
          </div>
          <div className="page-section__contacts__body">
            <div className="address-block">
              <div
                className="address-block"
                style={{ gridTemplateColumns: 'repeat(3, 1fr)', gap: '0 15px' }}
              >
                {address?.map(({ name, value }, index) => {
                  if (index === 2 || index === 3 || index === 4) {
                    return (
                      <div
                        key={index}
                        className={`page-section__contacts__input-block ${
                          index === address.length - 1 ? 'all-space' : ''
                        }`}
                      >
                        <label>{name}</label>
                        <input
                          onFocus={setDefaultBorder}
                          type="text"
                          placeholder={name}
                          defaultValue={value}
                          onChange={(e) => handleChangeAddress(index, e.target.value, setAddress)}
                        />
                      </div>
                    );
                  } else return null;
                })}
              </div>
              {address?.map(({ name, value }, index) => {
                if (index === 0 || index === 1 || index === 10 || index === 11) {
                  return (
                    <div key={index} className="page-section__contacts__input-block">
                      <label>{name}</label>
                      <input
                        onFocus={setDefaultBorder}
                        type="text"
                        placeholder={name}
                        defaultValue={value}
                        onChange={(e) => handleChangeAddress(index, e.target.value, setAddress)}
                      />
                    </div>
                  );
                } else return null;
              })}
              <div
                className="address-block"
                style={{ gridTemplateColumns: 'repeat(2, 1fr)', gap: '0 15px' }}
              >
                {address?.map(({ name, value }, index) => {
                  if (index === 5 || index === 6) {
                    return (
                      <div
                        key={index}
                        className={`page-section__contacts__input-block ${
                          index === address.length - 1 ? 'all-space' : ''
                        }`}
                      >
                        <label>{name}</label>
                        <input
                          onFocus={setDefaultBorder}
                          type="text"
                          placeholder={name}
                          defaultValue={value}
                          onChange={(e) => handleChangeAddress(index, e.target.value, setAddress)}
                        />
                      </div>
                    );
                  } else return null;
                })}
              </div>
              <div
                className="address-block"
                style={{ gridTemplateColumns: 'repeat(3, 1fr)', gap: '0 15px' }}
              >
                {address?.map(({ name, value }, index) => {
                  if (index === 7 || index === 8 || index === 9) {
                    return (
                      <div
                        key={index}
                        className={`page-section__contacts__input-block ${
                          index === address.length - 1 ? 'all-space' : ''
                        }`}
                      >
                        <label>{name}</label>
                        <input
                          onFocus={setDefaultBorder}
                          type="text"
                          placeholder={name}
                          defaultValue={value}
                          onChange={(e) => handleChangeAddress(index, e.target.value, setAddress)}
                        />
                      </div>
                    );
                  } else return null;
                })}
              </div>
              <div className="page-section__contacts__input-block">
                <label>{address[address.length - 1].name}</label>
                <input
                  onFocus={setDefaultBorder}
                  type="text"
                  placeholder={address[address.length - 1].name}
                  defaultValue={address[address.length - 1].value}
                  onChange={(e) =>
                    handleChangeAddress(address.length - 1, e.target.value, setAddress)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-section__contacts-btn">
          <button
            className="section-btn-gradient"
            onClick={() => {
              saveContacts();
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdminContacts;
