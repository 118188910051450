import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import DropDownProfileIcon from '../../../../general/assets/svg/DropDownProfileIcon';
import LocationProdStoresIcon from '../../../../general/assets/svg/LocationProdStoresIcon';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import { setReciept } from '../../../../general/redux/reducers/ClientCartProcessing-Reducer';
import { RootState } from '../../../../general/redux/store';
import createClientService from '../../../../general/services/client';
import { clientInstance } from '../../../../general/services/main/axiosInstances';
import { images } from '../../../../general/StaticData';
import { Address, Order } from '../../../../general/types/types';
import { formatToTwoPlaces } from '../../../../general/utils/Validations';
import PaymentReceiptUpload from '../../cart/proccesing/payment/DragAndDropCheck';

const OrderInfo: React.FC<{ order: Order; refresh: any }> = ({ order, refresh }) => {
  const dispatch = useDispatch();
  const [orderInfo, setOrdersInfo] = useState<{ field: string; value: string }[] | null>(null);
  const [addresses, setAddresses] = useState<(Address | null)[]>([]);
  const [isOpenedOrder, setIsOpenedOrder] = useState(false);
  const [currentFrame, setCurrentFrame] = useState<string>('');
  const reciept = useSelector((state: RootState) => state.clientCartProcessing.reciept);

  useEffect(() => {
    return () => {
      dispatch(setReciept(null));
    };
  }, [dispatch]);

  const { mutate: updateReciept } = useCustomMutation(
    createClientService(clientInstance).updateOrederBankTransactionReciept,
    undefined,
    {
      onSuccess: () => {
        dispatch(setReciept(null));
        refresh();
      },
    },
  );

  const getStaticShippingCost = useCallback(() => {
    const priceWithoutShipping = order?.orderedProducts.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0,
    );
    return order?.totalPrice - priceWithoutShipping - order.tax;
  }, [order?.orderedProducts, order?.totalPrice, order.tax]);

  const createOrdersInfo = useCallback(async (transactionAddress: Address) => {
    const baseInfo = [
      {
        field: 'Full name',
        value: `${transactionAddress.name} ${transactionAddress.lastName}`,
      },
      {
        field: 'Phone',
        value: transactionAddress.phone,
      },
      {
        field: 'Email',
        value: transactionAddress.email,
      },
    ];

    return [...baseInfo];
  }, []);

  const getTransactionAddresses = useCallback(async () => {
    if (order?.transaction !== null) {
      const { addressTo, billingAddress } = order.transaction;
      setAddresses([addressTo, billingAddress]);
    } else if (order?.productsStoresTransaction !== null) {
      const { billingAddress } = order.productsStoresTransaction;
      setAddresses([billingAddress]);
    }
  }, [order?.transaction, order?.productsStoresTransaction]);

  useEffect(() => {
    const processOrderInfo = async (address: Address) => {
      const info = await createOrdersInfo(address);
      setOrdersInfo(info);
      getTransactionAddresses();
    };

    if (order?.transaction) {
      processOrderInfo(order.transaction.addressTo);
    } else if (order?.productsStoresTransaction) {
      processOrderInfo(order.productsStoresTransaction.billingAddress);
    }
  }, [createOrdersInfo, getTransactionAddresses, order]);

  const renderTextBlock = (title: string, value: string | undefined) => {
    if (!value) return null;
    return (
      <div className="client__text-block">
        <span className="client__text-block__title">{title}</span>
        <span className="dots"></span>
        <span className="client__text-block__text large-text">{value}</span>
      </div>
    );
  };

  return (
    <div className="order-item">
      <div className="drop-down-arrow-section">
        <button
          className={`order-item__more-info__open-more ${isOpenedOrder ? 'opened' : ''}`}
          onClick={() => {
            setIsOpenedOrder(!isOpenedOrder);
          }}
        >
          <DropDownProfileIcon />
        </button>
      </div>
      <div className="order-item-top">
        <div className="order-main-info">
          <div className="order-item__order-info">
            <div className="order-item__order-status-data">
              <span
                className={`order-item__order-status order-${
                  order.orderStatuses[order.orderStatuses.length - 1].name.split(' ')[0]
                }`}
              >
                {order.orderStatuses[order.orderStatuses.length - 1].name}
              </span>
              <span className="order-item__order-data">
                {new Date(order.createdAt).toLocaleString().split(',')[0].split('/').join('.')}
              </span>
            </div>
            <span className="order-item__order-number">Order #{order.idFormatted}</span>
          </div>

          <div className="order-item__more-info__count">
            <span>{order.totalProductsQuantity}</span>
            <span>{order.totalProductsQuantity > 1 ? ' products ' : ' product '}</span>
            <span className={`order-payment ${order.isPaid ? 'is-paid' : 'is-not-paid'}`}>
              {order.isPaid ? 'Paid' : 'Not paid'}
            </span>
          </div>

          <div className="order-item__order-prices-payment__block">
            <div className="order-item__order-prices-payment">
              <div className="prices">
                <span>
                  Shipping:{' '}
                  <span className="numbers">{formatToTwoPlaces(getStaticShippingCost())} $</span>
                </span>
                <span>
                  Subtotal:{' '}
                  <span className="numbers">
                    {formatToTwoPlaces(
                      order.totalPrice - (getStaticShippingCost() || 0) - order.tax,
                    )}{' '}
                    $
                  </span>
                </span>
                <span>
                  Tax: <span className="numbers">{formatToTwoPlaces(order.tax)} $</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="total-price">
          <span className="order-total-price">
            Total price: <span> {formatToTwoPlaces(order.totalPrice)}$</span>
          </span>
        </div>
      </div>
      <div
        className={`order-item-info ${isOpenedOrder ? 'opened' : ''} order-${order.idFormatted}`}
      >
        <div className="order-item-info_container">
          <div className="order-item-info-top-wrapper">
            <div className="order-item-info__client-order">
              <div className="order-item-info__client">
                {orderInfo?.map(({ field, value }) => (
                  <div key={field} className="client__text-block">
                    <span className="client__text-block__title">{field}:</span>
                    <span className="client__text-block__text">{value}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="order-item-info__order">
              <ul>
                {order?.orderedProducts?.map((product, index) => (
                  <li key={index}>
                    <div className="order-product__info">
                      <Link to={`/product/${product.url}`}>
                        <div className="order-product__info__img">
                          <img src={product?.image?.url || images.defaultImg} alt="" />
                        </div>
                        <span className="order-product__info__name">{product.name}</span>
                      </Link>
                    </div>
                    <div className="order-product__price-count">
                      <span className="order-product__count">{product.quantity}</span> x{' '}
                      <span className="order-product__price">
                        {(
                          product.price -
                          product.price * (product.percentageDiscount / 100)
                        ).toFixed(2)}{' '}
                        $
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {order?.isUploadLaterBankTransactionReciept && <PaymentReceiptUpload />}
          {reciept && (
            <button
              className="section-btn-gradient"
              onClick={() => {
                updateReciept({ orderId: order.id.toString(), recieptId: reciept });
              }}
            >
              Upload reciept
            </button>
          )}
          {order?.productsStoresTransaction && (
            <div className="shipping-stores_locations">
              <span className="header">Available location</span>
              <div
                className="available_stores"
                style={{ marginBottom: currentFrame ? '20px' : '0' }}
              >
                {order?.productsStoresTransaction?.productsStores.map((store, storeIndex) => (
                  <div
                    key={storeIndex}
                    className="store-item"
                    style={{ position: 'relative' }}
                    onClick={() => {
                      setCurrentFrame(store.mapFrame);
                    }}
                  >
                    <LocationProdStoresIcon />
                    <div className="info-block">
                      <span className="country">{store.country}</span>
                      <span className="address">{store.address}</span>
                    </div>
                  </div>
                ))}
              </div>
              <div className="map" dangerouslySetInnerHTML={{ __html: currentFrame }}></div>
            </div>
          )}
          <div className="addresses">
            {addresses?.map((address, index) => (
              <div key={index}>
                {order?.productsStoresTransaction ? (
                  <span className="header">Billing address</span>
                ) : (
                  <span className="header">
                    {index === 0 && 'Shipping address'}
                    {index === 1 && 'Billing address'}
                  </span>
                )}

                <div
                  className={`info ${index === 1 ? 'billing' : ''}`}
                  style={{ background: index === 0 ? '#25A8CF1A' : '' }}
                >
                  {renderTextBlock('Full name', `${address?.name} ${address?.lastName}`)}
                  {renderTextBlock('Country', address?.country)}
                  {renderTextBlock('City', address?.city)}
                  {renderTextBlock('State', address?.state)}
                  {renderTextBlock('Street 1', address?.street1)}
                  {renderTextBlock('Street 2', address?.street2)}
                  {renderTextBlock('Street 3', address?.street3)}
                  {renderTextBlock('Street number', address?.streetNumber)}
                  {renderTextBlock('Zip', address?.zip)}
                  {renderTextBlock('Email', address?.email)}
                  {renderTextBlock('Phone', address?.phone)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderInfo;
