import './AdminClient.scss';

import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CloseModalCrossIconWhite from '../../../../general/assets/svg/CloseModalCrossIconWhite';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import createClientService from '../../../../general/services/client';
import { adminInstance } from '../../../../general/services/main/axiosInstances';

interface RemoveClientModalProps {
  isOpen: (isOpen: boolean) => void;
}

const RemoveClientModal: React.FC<RemoveClientModalProps> = ({ isOpen }) => {
  const { id } = useParams();

  const { mutate: deleteClient } = useCustomMutation(
    createClientService(adminInstance).deleteClient,
    undefined,
    {
      onSuccess() {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Client was sucessfully deleted' },
          }),
        );

        navigate('/admin/clients');
        isOpen(false);
      },
    },
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const removeUser = async () => {
    deleteClient(id as string);
  };

  return (
    <div className="warning-modal-window">
      <div className="warning-modal-window__overlay" onClick={() => isOpen(false)} />
      <div className="warning-modal-window__content">
        <button className="warning-modal-window__content__close-btn" onClick={() => isOpen(false)}>
          <CloseModalCrossIconWhite />
        </button>
        <div className="warning-modal-window__content__title">Warning</div>
        <div className="warning-modal-window__content__text">
          You are about to delete a user.
          <br /> His orders will be deleted along with him.
        </div>
        <div className="warning-modal-window__content__action">
          <button className="section-btn-gradient" onClick={removeUser}>
            Delete client permanently
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveClientModal;
