import './Cart.scss';

import React, { ChangeEvent, FocusEvent, MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import BucketIcon from '../../../general/assets/svg/BucketIcon';
import {
  changeCartItemCount,
  removeCartItem,
  setCartItemCount,
} from '../../../general/redux/reducers/ClientCart-Reducer';
import { images } from '../../../general/StaticData';
import { ProductMax } from '../../../general/types/types';
import { formatToTwoPlaces } from '../../../general/utils/Validations';

const exceptThisSymbols = ['e', 'E', '+', '-', '.', '0'];

interface CartItem {
  p: ProductMax;
  c: number;
}

interface TableRowProps {
  row: CartItem;
}

const TableRow: React.FC<TableRowProps> = ({ row }) => {
  const dispatch = useDispatch();
  const [count, setCount] = useState<number>(row.c);

  const changeCountInput = (e: ChangeEvent<HTMLInputElement>) => {
    let value = +e.target.value;
    if (value > 999) {
      value = 999;
    }

    if (value >= 1) {
      dispatch(setCartItemCount({ p: row.p.id, c: value }));
      setCount(value);
    }
  };

  const setDefaultCount = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value.trim() === '' || +e.target.value === 0) {
      setCount(row.c);
    }
  };

  const changeCount = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (e.currentTarget.hasAttribute('data-plus') && count < 999) {
      dispatch(changeCartItemCount({ p: row.p.id, c: '++' }));
      setCount((prevCount) => prevCount + 1);
    }
    if (e.currentTarget.hasAttribute('data-minus') && count >= 2) {
      dispatch(changeCartItemCount({ p: row.p.id, c: '--' }));
      setCount((prevCount) => prevCount - 1);
    }
  };

  const deleterRow = () => {
    dispatch(removeCartItem(row?.p?.id));
  };

  const getFormattedPrice = (row: CartItem) => {
    const price = row.p.price;
    return price !== undefined ? `$${formatToTwoPlaces(price)}` : '$0.00';
  };

  const imageUrl = Object.prototype.hasOwnProperty.call(row?.p, 'image')
    ? row?.p?.image?.url || images.defaultImg
    : row?.p?.images?.[0]?.url || images.defaultImg;

  return (
    <tr>
      <td className="tbody__modal">
        <Link to={`/${row.p.url}`}>
          <div className="tbody__modal__image">
            <div
              className="img"
              style={{
                backgroundImage: `url(${imageUrl})`,
              }}
            ></div>
          </div>
          <div className="tbody__modal__text">
            <span className="body_modal_text__name">{row.p.name}</span>
            <div className="body_modal_text__ch">{row.p.manufacturer}</div>
            <span className="body_modal_text__seller">Seller: AsicXchange</span>
          </div>
        </Link>
      </td>
      <td>
        <div className="tbody__modal__td__div">
          {row.p.amount === 0 ? 'Pre-order' : 'Available'}
        </div>
      </td>
      <td>
        <div className="tbody__modal__td__div">
          <div className="tbody__modal__td__div__price">
            {row.p.percentageDiscount === 0 ? (
              getFormattedPrice(row)
            ) : (
              <>
                <span className="price-without-discount">{getFormattedPrice(row)}</span>
                <span className="price-with-discount">{`$${formatToTwoPlaces(
                  row.p.price - row.p.price * (row.p.percentageDiscount / 100),
                )}`}</span>
              </>
            )}
          </div>
        </div>
      </td>
      <td>
        <div
          style={{ justifyContent: 'center' }}
          className="product-section__info-characteristics__counts-buttons__counts unselectable"
        >
          <button className="counts-buttons__counts-minus" onClick={changeCount} data-minus>
            -
          </button>
          <input
            type="number"
            className="counts-buttons__counts-count"
            value={count}
            onChange={changeCountInput}
            onBlur={setDefaultCount}
            onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
          />
          <button className="counts-buttons__counts-plus" onClick={changeCount} data-plus>
            +
          </button>
        </div>
      </td>
      <td>
        <div className="tbody__modal__td__div">
          <div className="tbody__modal__td__div__price">
            {row.p.percentageDiscount === 0 ? (
              <span style={{ fontWeight: 600 }}>${(row.p.price * count).toFixed(2)}</span>
            ) : (
              <>
                <span className="price-without-discount">{`$${(row.p.price * count).toFixed(
                  2,
                )}`}</span>
                <span className="price-with-discount" style={{ fontWeight: 600 }}>
                  {`$${formatToTwoPlaces(
                    row.p.price * count - row.p.price * count * (row.p.percentageDiscount / 100),
                  )}`}
                </span>
              </>
            )}
          </div>
        </div>
      </td>
      <td>
        <div className="tbody__modal__td__div">
          <button onClick={deleterRow}>
            <BucketIcon />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default TableRow;
