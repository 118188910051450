import './AdminProduct.scss';

import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import PlusIcon from '../../../../general/assets/svg/PlusIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import { setInfo } from '../../../../general/redux/reducers/Error-Reducer';
import { addChoosenStore } from '../../../../general/redux/reducers/Product-Reducer';
import { RootState } from '../../../../general/redux/store';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../general/services/products';
import { ICategory } from '../../../../general/types/types';
import AdditionalCharacteristic from './AdditionalCharacteristics';
import Batch from './batch/Batch';
import UploadImages from './drag&drop/UploadImages';
import MainCharacteristics from './main-characteristics/MainCharacteristics';
import MarketSelector from './MarketSelector';

interface AdminProductProps {
  isEdit: boolean;
}

const AdminProduct: React.FC<AdminProductProps> = ({ isEdit }) => {
  const stores = useSelector((state: RootState) => state.product.choosenStores);
  const local = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const [isPreloader, setIsPreloader] = useState(false);

  // Categories
  const [categories, setCategories] = useState<ICategory[]>();
  const [currentCategory, setCurrentCategory] = useState<ICategory>();

  //Batch
  const [date, onChange] = useState<Date | null>(new Date());

  const getCategories = useCallback(async () => {
    const { data } = await createProductsService(adminInstance).getProductsCategories();
    if (data.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: data.message,
          r: Object.prototype.hasOwnProperty.call(data, 'response')
            ? { d: data.response.data, s: data.response.statusText }
            : '',
        }),
      );
    } else {
      setCategories(data);
    }
  }, [dispatch]);

  const [isEditProduct, setIsEditProduct] = useState<any>({ id: '' });

  const changecategory = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrentCategory(categories?.find((item) => item.id === e.target.value));
  };

  const getProductCategories = useCallback(async () => {
    const { data } = await createProductsService(adminInstance).getProductsCategories();
    const categoryList = data;

    if (categoryList.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: categoryList.message,
          r: categoryList.hasOwnProperty('response')
            ? {
                d: categoryList.response.data,
                s: categoryList.response.statusText,
              }
            : '',
        }),
      );
    } else {
      setCategories(categoryList);
    }

    return categoryList;
  }, [dispatch]);

  const getEditProduct = useCallback(async () => {
    const categoryList = await getProductCategories();
    const { data } = await createProductsService(adminInstance).getEditProduct(id as string);

    data.stores.forEach((store: any) => {
      if (!stores.some((chosenStore: any) => chosenStore.id === store.id)) {
        dispatch(addChoosenStore(store));
      }
    });

    if (data.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: data.message,
          r: Object.prototype.hasOwnProperty.call(data, 'response')
            ? { d: data.response.data, s: data.response.statusText }
            : '',
        }),
      );
    } else {
      setIsEditProduct(data);
      setCurrentCategory(categoryList.find((item: any) => item.id === data.categoryId));
      onChange(data?.batch);
    }
  }, [dispatch, getProductCategories, id, stores]);

  useEffect(() => {
    if (isEdit) {
      getEditProduct();
    } else {
      getCategories();
    }
  }, [local.key, getCategories, getEditProduct, isEdit]);

  // UPDATE PRODUCT
  const filesArray: any[] = [];
  const dragedImgs: any[] = [];

  const changeInAdditionalCharacteristics: any[] = [];
  const removedAdditionalCharacteristics: any[] = [];

  const removedImages: any[] = [];

  const updateImages = async () => {
    if (removedImages.length > 0) {
      removedImages.forEach(async (id) => {
        await createProductsService(adminInstance).deleteProductImg(id);
      });
    }

    const updatedImagesArray = filesArray.map((image) => {
      const found = dragedImgs.find((item) => item.goal === image.url);
      return {
        id: image.id,
        order: found ? found.targetIndex + 1 : image.order,
      };
    });

    const newUpdatedImagesArray = updatedImagesArray.sort((a, b) => a.order - b.order);

    if (updatedImagesArray) {
      await createProductsService(adminInstance).updateImagesOredr({
        productId: id as string,
        images: newUpdatedImagesArray,
      });
    }

    if (filesArray.length > 0) {
      const formData = new FormData();

      filesArray.forEach((file) => {
        formData.append('Images', file);
      });

      await createProductsService(adminInstance).postImagesProduct({
        id: id as string,
        data: formData,
      });
    }
  };

  const updateAdditionalCharacteristics = async () => {
    const ArraylCharacteristics = document.querySelectorAll<HTMLDivElement>(
      '.additional-characteristic__block__input-block',
    );

    ArraylCharacteristics.forEach(async (block) => {
      if (changeInAdditionalCharacteristics.indexOf(block.dataset.id) !== -1) {
        const inputs = block.querySelectorAll<HTMLInputElement>('input');
        await createProductsService(adminInstance).putProductCharacteristics({
          id: block.dataset.id as string,
          productId: id as string,
          name: inputs[1].value,
          value: inputs[2].value,
          order: inputs[0].value,
        });
      } else if (
        !isEditProduct?.characteristics?.some((item: any) => item.id === block.dataset.id)
      ) {
        const inputs = block.querySelectorAll<HTMLInputElement>('input');
        await createProductsService(adminInstance).postAdditionalCharacteristic({
          productId: id as string,
          name: inputs[1].value,
          value: inputs[2].value,
          order: inputs[0].value,
        });
      }
    });

    if (removedAdditionalCharacteristics.length > 0) {
      removedAdditionalCharacteristics.forEach(async (id) => {
        await createProductsService(adminInstance).deleteProductCharacteristics(id);
      });
    }
  };

  const updateMainMiningCharacteristics = () => {
    const characteristicBlock = document.querySelector<HTMLDivElement>(
      '.page-section__characteristics__characteristic',
    );
    const characteristicInputs = characteristicBlock?.querySelectorAll<HTMLInputElement>('input');
    const hasrateSelect = characteristicBlock?.querySelector<HTMLSelectElement>('.hashrate-select');
    const hasrateType = hasrateSelect?.options[hasrateSelect.selectedIndex].value;

    const result = {
      productId: id as string,
      manufacturer: characteristicInputs?.[0].value as string,
      condition: characteristicInputs?.[1].value as string,
      algorithm: characteristicInputs?.[2].value as string,
      hashrate: `${characteristicInputs?.[3].value} ${hasrateType}` as string,
      energyConsumption: characteristicInputs?.[4].value as string,
      energyEfficiency: characteristicInputs?.[5].value as string,
      countryLocation: characteristicInputs?.[6].value as string,
      stores: stores.map((store: any) => store.id),
    };

    return result;
  };

  const updateMainGlobalCharacteristic = async () => {
    const block = document.querySelector<HTMLDivElement>(
      '.page-section__characteristics__characteristic',
    );
    const inputs = block?.querySelectorAll<HTMLInputElement>('input');

    inputs?.forEach(async (input) => {
      const { data } = await createProductsService(adminInstance).putProductMainGeneralOfProduct({
        generalMainCharacteristicId: input.dataset.id as string,
        value: input?.value?.trim(),
      });
      if (data.name === 'AxiosError') {
        dispatch(
          setInfo({
            n: data.message,
            r: Object.prototype.hasOwnProperty.call(data, 'response')
              ? { d: data.response.data, s: data.response.statusText }
              : '',
          }),
        );
      }
    });
  };

  const updateMarket = async () => {
    const market = document.querySelector<HTMLDivElement>(
      '.market-payment-status__current-options__name',
    )?.innerHTML;

    const result = {
      productId: id as string,
      isRetail: market === 'Retail' ? true : false,
      isWholesale: market === 'Wholesale' ? true : false,
    };

    await createProductsService(adminInstance).putProductMarket(result);
  };

  const updateMainInformation = () => {
    const mainInfoBlock = document.querySelector<HTMLDivElement>(
      '.product-content-section__content__body',
    );
    const mainInfoInputs =
      mainInfoBlock?.querySelectorAll<HTMLInputElement>("input[name='default']");
    const mainInfoTextarea = mainInfoBlock?.querySelector<HTMLTextAreaElement>('textarea');

    const isActiveBlock = document.querySelector<HTMLDivElement>(
      '.product-content-section__side-bar__active__btn',
    );
    const isActiveBtn = isActiveBlock?.querySelector<HTMLInputElement>(
      'input[name=status]:checked',
    )?.value;

    const result = {
      id: id as string,
      batch: date as Date,
      name: mainInfoInputs?.[0].value as string,
      description: mainInfoTextarea?.value as string,
      price: mainInfoInputs?.[1].value as string,
      minimalOrderQuantity: mainInfoInputs?.[3].value as string,
      rating: mainInfoInputs?.[5].value as string,
      amount: mainInfoInputs?.[4].value as string,
      isActive: isActiveBtn === 'Active' ? true : false,
      percentageDiscount: mainInfoInputs?.[2].value as string,
    };

    return result;
  };

  const updatedProduct = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsPreloader(true);
    let response;
    try {
      const info = updateMainInformation();
      const { data } = await createProductsService(adminInstance).putProductMainInformation(info);
      response = data;

      if (currentCategory?.areMiningCharacteristics) {
        const characteristics = updateMainMiningCharacteristics();
        const { data } =
          await createProductsService(adminInstance).putProductMainMiningCharacteristics(
            characteristics,
          );
        response = data;
      } else {
        await updateMainGlobalCharacteristic();
      }

      if (response.name === 'AxiosError') {
        throw new Error();
      }

      await updateMarket();
      await updateAdditionalCharacteristics();
      await updateImages();
      navigate('/admin/products');
    } catch (error) {
      dispatch(
        setInfo({
          n: response.message,
          r: Object.prototype.hasOwnProperty.call(response, 'response')
            ? { d: response.response.data, s: response.response.statusText }
            : '',
        }),
      );
    }

    setIsPreloader(false);
  };

  // CREATE PRODUCT
  const loadMainGlobalCharacteristic = (id: string) => {
    const block = document.querySelector<HTMLDivElement>(
      '.page-section__characteristics__characteristic',
    );
    const inputs = block?.querySelectorAll<HTMLInputElement>('input');

    const arr: any[] = [];

    inputs?.forEach((input) => {
      arr.push({
        mainGeneralCharacteristicDefinitionId: input.dataset.id as string,
        value: input.value.trim(),
      });
    });

    return arr;
  };

  const createdefaultJSONProduct = () => {
    const mainInfoBlock = document.querySelector<HTMLDivElement>(
      '.product-content-section__content__body',
    );
    const mainInfoInputs =
      mainInfoBlock?.querySelectorAll<HTMLInputElement>("input[name='default']");
    const mainInfoTextarea = mainInfoBlock?.querySelector<HTMLTextAreaElement>('textarea');

    const isActiveBlock = document.querySelector<HTMLDivElement>(
      '.product-content-section__side-bar__active__btn',
    );
    const isActiveBtn = isActiveBlock?.querySelector<HTMLInputElement>(
      'input[name=status]:checked',
    )?.value;

    const market = document.querySelector<HTMLDivElement>(
      '.market-payment-status__current-options__name',
    )?.innerHTML;

    if (currentCategory?.areMiningCharacteristics) {
      const characteristicBlock = document.querySelector<HTMLDivElement>(
        '.page-section__characteristics__characteristic',
      );
      const characteristicInputs = characteristicBlock?.querySelectorAll<HTMLInputElement>('input');
      const hasrateSelect =
        characteristicBlock?.querySelector<HTMLSelectElement>('.hashrate-select');
      const hasrateType = hasrateSelect?.options[hasrateSelect.selectedIndex].value;

      const result = {
        name: mainInfoInputs?.[0].value,
        batch: date,
        description: mainInfoTextarea?.value,
        price: mainInfoInputs?.[1].value,
        minimalOrderQuantity: mainInfoInputs?.[3].value,
        amount: mainInfoInputs?.[4].value,
        manufacturer: characteristicInputs?.[0].value,
        condition: characteristicInputs?.[1].value,
        algorithm: characteristicInputs?.[2].value,
        hashrate: `${characteristicInputs?.[3].value} ${hasrateType}`,
        energyConsumption: characteristicInputs?.[4].value,
        energyEfficiency: characteristicInputs?.[5].value,
        countryLocation: characteristicInputs?.[6].value,
        isRetail: market === 'Retail' ? true : false,
        isWholesale: market === 'Wholesale' ? true : false,
        isActive: isActiveBtn === 'Active' ? true : false,
        percentageDiscount: mainInfoInputs?.[2].value,
        rating: mainInfoInputs?.[5].value,
        categoryId: currentCategory?.id,
        mainGeneralCharacteristics: null,
      };

      return result;
    } else {
      const result = {
        name: mainInfoInputs?.[0].value,
        batch: date,
        description: mainInfoTextarea?.value,
        price: mainInfoInputs?.[1].value,
        minimalOrderQuantity: mainInfoInputs?.[3].value,
        amount: mainInfoInputs?.[4].value,
        manufacturer: null,
        condition: null,
        algorithm: null,
        hashrate: null,
        energyConsumption: null,
        energyEfficiency: null,
        countryLocation: null,
        isRetail: market === 'Retail' ? true : false,
        isWholesale: market === 'Wholesale' ? true : false,
        isActive: isActiveBtn === 'Active' ? true : false,
        percentageDiscount: mainInfoInputs?.[2].value,
        rating: mainInfoInputs?.[5].value,
        categoryId: currentCategory?.id,
        mainGeneralCharacteristics: loadMainGlobalCharacteristic(id as string),
      };

      return result;
    }
  };

  const loadAdditionalCharacteristicProduct = async (id: string) => {
    const ArraylCharacteristics = document.querySelectorAll<HTMLDivElement>(
      '.additional-characteristic__block__input-block',
    );
    ArraylCharacteristics.forEach(async (block) => {
      const inputs = block.querySelectorAll<HTMLInputElement>('input');

      await createProductsService(adminInstance).postAdditionalCharacteristic({
        productId: id,
        name: inputs[1].value,
        value: inputs[2].value,
        order: inputs[0].value,
      });
    });
  };

  const loadImagesProduct = async (id: string) => {
    const formData = new FormData();

    filesArray.forEach((file) => {
      formData.append('Images', file);
    });

    await createProductsService(adminInstance).postImagesProduct({ id: id, data: formData });
  };

  const createProuct = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const productData = createdefaultJSONProduct();
    const { data } = await createProductsService(adminInstance).postProduct(productData);
    if (data.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: data.message,
          r: Object.prototype.hasOwnProperty.call(data, 'response')
            ? { d: data.response.data, s: data.response.statusText }
            : '',
        }),
      );
    } else {
      await loadAdditionalCharacteristicProduct(data);
      await loadImagesProduct(data);
      navigate('/admin/products');
    }
  };

  const copyProduct = async () => {
    setIsPreloader(true);
    const { data } = await createProductsService(adminInstance).postCopyProducts(isEditProduct?.id);

    if (data.name === 'AxiosError') {
      dispatch(
        setInfo({
          n: data.message,
          r: Object.prototype.hasOwnProperty.call(data, 'response')
            ? { d: data.response.data, s: data.response.statusText }
            : '',
        }),
      );
    }
    setIsPreloader(false);
    setIsEditProduct({ id: '' });
    navigate(`/admin/products/product/${data}`);
  };

  return (
    <div className="page-section">
      <div className="page-section__top">
        <div className="page-section__top__title">PRODUCT CREATION</div>
        {isEdit && (
          <div className="page-section__buttons">
            <button onClick={copyProduct}>
              <PlusIcon stroke="white" />
              <span>Copy</span>
            </button>
          </div>
        )}
      </div>
      {isEdit === true && isEditProduct?.id === '' ? (
        <div className="preloader-block">
          <Preloader />
        </div>
      ) : (
        <div className="page-section__content">
          {isPreloader && (
            <div className="page-update-overlay">
              <Preloader />
            </div>
          )}
          {categories === null ? (
            <div className="preloader-block">
              <Preloader />
            </div>
          ) : (
            <>
              <div className="product-content-section">
                <div className="product-content-section__content__main">
                  <div className="product-content-section__content">
                    <div className="product-content-section__content__header">
                      <span>Main information</span>
                    </div>
                    <div className="product-content-section__content__body">
                      <div className="product-content-section__content__name-batch ">
                        <div className="product-content-section__content__name product__input">
                          <label>
                            Item name <span>*</span>
                          </label>
                          <input
                            type="text"
                            name="default"
                            defaultValue={isEdit === true ? isEditProduct?.name : ''}
                          />
                        </div>
                        <div className="product-content-section__content__batch ">
                          <label>Batch</label>
                          <Batch date={date} onChange={onChange} />
                        </div>
                      </div>
                      <div className="product-content-section__content__description product__input">
                        <label>Description</label>
                        <textarea
                          defaultValue={isEdit === true ? isEditProduct?.description : ''}
                        />
                      </div>
                      <div className="product-content-section__content__info">
                        <div className="content__info__block product__input ">
                          <label>
                            Price $ <span>*</span>
                          </label>
                          <input
                            type="number"
                            name="default"
                            defaultValue={isEdit === true ? isEditProduct?.price : 0}
                          />
                        </div>
                        <div className="content__info__block product__input ">
                          <label>
                            Discount % <span>*</span>
                          </label>
                          <input
                            type="number"
                            name="default"
                            defaultValue={isEdit === true ? isEditProduct?.percentageDiscount : 0}
                          />
                        </div>
                        <div className="content__info__block product__input ">
                          <label>
                            MOQ <span>*</span>
                          </label>
                          <input
                            type="number"
                            name="default"
                            defaultValue={isEdit === true ? isEditProduct?.minimalOrderQuantity : 0}
                          />
                        </div>
                        <div className="content__info__block product__input ">
                          <label>
                            Count <span>*</span>
                          </label>
                          <input
                            type="number"
                            name="default"
                            defaultValue={isEdit === true ? isEditProduct?.amount : 0}
                          />
                        </div>
                        <div className="content__info__block product__input ">
                          <label>
                            Rating <span>*</span>
                          </label>
                          <input
                            type="number"
                            name="default"
                            defaultValue={isEdit === true ? isEditProduct?.rating : 0}
                          />
                        </div>
                        <div className="content__info__block product__input ">
                          <label>
                            Categories <span>*</span>
                          </label>
                          <div className="content__info__block__select">
                            <select
                              className="content__info__block"
                              defaultValue={isEdit === true ? isEditProduct?.categoryId : ''}
                              onChange={changecategory}
                              disabled={isEdit}
                            >
                              <option value="" disabled hidden data-id="">
                                Select a category
                              </option>
                              {categories?.map((category) => (
                                <option value={category.id} data-id={category.id} key={category.id}>
                                  {category.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="page-section__characteristics">
                    <MainCharacteristics
                      category={currentCategory as ICategory}
                      isEdit={isEdit}
                      editProduct={isEditProduct}
                    />
                  </div>
                  <div className="page-section__characteristics">
                    <AdditionalCharacteristic
                      characteristics={
                        isEdit === true ? isEditProduct?.characteristics : [{ id: 1 }]
                      }
                      removeArray={isEdit === true ? removedAdditionalCharacteristics : [{ id: 1 }]}
                      changeArray={isEdit === true ? changeInAdditionalCharacteristics : null}
                    />
                  </div>
                </div>
                <div className="product-content-section__side-bar">
                  <div className="product-content-section__side-bar__active">
                    <div className="product-content-section__side-bar__active__title">
                      <span>Status</span>
                    </div>
                    <div className="product-content-section__side-bar__active__btn">
                      <div>
                        <input
                          type="radio"
                          id="product_active"
                          name="status"
                          value="Active"
                          defaultChecked={isEdit === false ? false : isEditProduct?.isActive}
                        />
                        <label htmlFor="product_active">Active</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="product_disabled"
                          name="status"
                          value="Disabled"
                          defaultChecked={isEdit === false ? true : !isEditProduct?.isActive}
                        />
                        <label htmlFor="product_disabled">Disabled</label>
                      </div>
                    </div>
                  </div>
                  <MarketSelector
                    currentStatus={isEdit === false ? '-' : isEditProduct?.isRetail}
                  />
                  <div className="product-content-section__side-bar__image">
                    <div className="product-content-section__side-bar__image-titel">Image</div>
                    <div className="product-content-section__side-bar__image-loaders">
                      <UploadImages
                        filesArray={filesArray}
                        dragedImgs={dragedImgs}
                        editImages={isEdit === true ? isEditProduct?.images : []}
                        removeArray={isEdit === true ? removedImages : []}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="save-proudct-section">
                <button
                  className="section-btn-gradient"
                  onClick={isEdit === true ? updatedProduct : createProuct}
                >
                  Save
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminProduct;
