import './NewsletterWorkshop.scss';

import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Preloader, { preloaderStyles } from '../../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../../general/hooks/useCustomQuerry';
import useRequired from '../../../../../general/hooks/useRequired';
import { queryKeys } from '../../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../../general/redux/reducers/Error-Reducer';
import createMailingService from '../../../../../general/services/mailing';
import { adminInstance } from '../../../../../general/services/main/axiosInstances';
import { Newsletter } from '../../../../../general/types/types';
import TextEditor, { sendImgSettingsConstatns } from '../../../articles/article/Editor/TextEditor';

// Function to add unsubscribe button
const addUnsubscribeButton = (baseUrl: string): HTMLAnchorElement => {
  const link = document.createElement('a');

  link.textContent = 'Unsubscribe';
  link.style.padding = '4px 20px';
  link.style.textDecoration = 'none';
  link.style.fontSize = '16px';
  link.style.cursor = 'pointer';
  link.style.border = 'none';
  link.style.borderRadius = '60px';
  link.style.background = `var(--gradient-10, linear-gradient(94deg, #25a8cf -23.33%, #8540bb 154.23%))`;
  link.style.color = 'white';
  link.id = 'unsubscribe';
  link.href = `${baseUrl}/unsubscribe`;

  return link;
};

const NewsletterWorkshop: React.FC = () => {
  const dispatch = useDispatch();
  const baseUrl = window.location.origin;
  const editor = useRef<any>(null);

  const [title, setTitle] = useState<string>('');
  const { errors, setError } = useRequired();

  const { data: postToEdit } = useCustomQuery<Newsletter>(
    queryKeys.NEWSLETTER_WORSHOP_GET,
    createMailingService(adminInstance).getNewsLetter,
    (data: any) => {
      return data.items[0];
    },
    undefined,
    {
      onSuccess(lastLetter: any) {
        setTitle(lastLetter.title);

        const foundDiv = findUnsubscribe(lastLetter.content);
        editor.current.fromHTML(foundDiv?.querySelector('div')?.innerHTML || '');
      },
    },
  );

  const { mutate: updateNewsLetter, isLoading: updating } = useCustomMutation(
    createMailingService(adminInstance).updateNewsLetter,
    undefined,
    {
      onSuccess() {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Draft successfully changed' },
          }),
        );
      },
    },
  );

  const { mutate: createNewsLetter, isLoading: creating } = useCustomMutation(
    createMailingService(adminInstance).createNewsLetter,
    undefined,
    {
      onSuccess() {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Draft successfully created' },
          }),
        );
      },
    },
  );

  const insertUnsubscribe = (content: string): string => {
    const generalDiv = document.createElement('div');
    generalDiv.style.display = 'flex';
    generalDiv.style.flexDirection = 'column';
    generalDiv.style.width = '100%';
    generalDiv.style.overflow = 'auto';

    const temp = document.createElement('div');
    temp.id = 'with_unsubscribe';
    temp.style.width = '50%';
    temp.style.textAlign = 'justify';
    temp.style.margin = '0 auto';

    const wrapperDiv = document.createElement('div');
    wrapperDiv.innerHTML = content;

    const unsubscribeDiv = document.createElement('div');
    unsubscribeDiv.style.clear = 'both';
    unsubscribeDiv.style.marginTop = '15px';
    unsubscribeDiv.innerHTML += addUnsubscribeButton(baseUrl).outerHTML;

    temp.appendChild(wrapperDiv);
    temp.appendChild(unsubscribeDiv);
    generalDiv.appendChild(temp);

    return generalDiv.outerHTML;
  };

  const findUnsubscribe = (content: string): HTMLElement | null => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = content;

    return tempDiv.querySelector('#with_unsubscribe');
  };

  const handleSaveDraft = () => {
    if (!title) {
      setError('name', 'Title is required');
      return;
    }

    const parser = new DOMParser();
    const doc = editor.current.toHTML();
    const parsedDoc = parser.parseFromString(doc, 'text/html');

    let content = parsedDoc.body.innerHTML.replace(/percent/gi, '%');
    content = insertUnsubscribe(content);

    const textContent = parsedDoc.body.textContent;
    if (!textContent) {
      setError('description', 'Description is required');
      return;
    }

    if (postToEdit !== null) {
      updateNewsLetter({
        id: postToEdit?.id as string,
        title: title,
        content: content,
        timeToPost: postToEdit?.timeToPost as string,
      });
    } else {
      createNewsLetter({
        title: title,
        content: content,
      });
    }
  };

  return (
    <div className="container">
      <div className="item">
        <label className="input-header">Title</label>
        <input
          placeholder={errors.name || ''}
          className={errors.name ? 'error_required' : ''}
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          id="post_name"
          type="text"
          required
        />
      </div>
      <div className="item">
        <TextEditor
          ref={editor}
          errorDescriptionRequired={errors.description || ''}
          sendImgSettings={sendImgSettingsConstatns.mailing}
          isNeedVideo={false}
        />
      </div>
      <div className="item">
        <button onClick={handleSaveDraft} className="button">
          {updating || creating ? (
            <>
              <Preloader style={preloaderStyles.DARK} />
            </>
          ) : (
            'Save draft'
          )}
        </button>
      </div>
    </div>
  );
};

export default NewsletterWorkshop;
