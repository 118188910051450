import './AdminContactUs.scss';

import React, { useEffect } from 'react';

import AdminGreenSuccesIcon from '../../../../general/assets/svg/AdminGreenSuccesIcon';
import AdminRedCrossIcon from '../../../../general/assets/svg/AdminRedCrossIcon';
import AdminRemoveIcon from '../../../../general/assets/svg/AdminRemoveIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../general/queryKeys';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createMessagesService from '../../../../general/services/messages';
import { PaggingObj, SimpleMessage } from '../../../../general/types/types';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';

interface ActionButtonsProps {
  item: SimpleMessage;
  deleteMethod: (e: React.MouseEvent<HTMLButtonElement>) => void;
  refreshMethod: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ item, deleteMethod, refreshMethod }) => {
  const { mutate: putSimpleMessageStatus } = useCustomMutation(
    createMessagesService(adminInstance).putSimpleMessageStatus,
  );

  const updateStatus = async () => {
    putSimpleMessageStatus({
      id: item.id,
      isProcessed: !item.isProcessed,
    });
    refreshMethod();
  };

  return (
    <>
      <button
        style={{ width: 'auto' }}
        onClick={updateStatus}
        className="table-block__tbody__change-item__button"
      >
        {item.isProcessed ? <AdminRedCrossIcon /> : <AdminGreenSuccesIcon />}
      </button>
      <button
        data-id={item.id}
        onClick={deleteMethod}
        className="table-block__tbody__change-item__button"
      >
        <AdminRemoveIcon />
      </button>
    </>
  );
};

const AdminContactUs: React.FC = () => {
  const { page, pageSize, setPage, setPageSize } = usePagination();

  const {
    data: messages,
    isLoading: messagesLoading,
    refetch: getData,
  } = useCustomQuery<PaggingObj<SimpleMessage>>(queryKeys.SIMPLE_MESSAGES_ADMIN, () =>
    createMessagesService(adminInstance).getSimpleMessage(pageSize, page),
  );

  const { mutate: deleteSimpleMessage } = useCustomMutation(
    createMessagesService(adminInstance).deleteSimpleMessage,
  );

  useEffect(() => {
    getData();
  }, [page, pageSize, getData]);

  const actionButtons = (
    item: SimpleMessage,
    deleteMethod?: (item: SimpleMessage) => void,
    refreshMethod?: (item: SimpleMessage) => void,
  ): React.ReactNode => {
    return (
      <ActionButtons
        item={item}
        deleteMethod={(e) => deleteMethod && deleteMethod(item)}
        refreshMethod={() => refreshMethod && refreshMethod(item)}
      />
    );
  };

  const deleteItem = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id;
    if (id) {
      deleteSimpleMessage(id);
      getData();
    }
  };

  return (
    <>
      <div className="page-section__content">
        <div className="page-section__buttons"></div>
        <div className="page-section__content__list-section">
          <div className="list-section__content">
            {messagesLoading && (
              <div className="page-update-overlay">
                <Preloader />
              </div>
            )}
            <CustomTable
              data={messages?.items || []}
              headers={['contact-name', 'phone', 'date', 'message-status']}
              deleteMethod={deleteItem}
              actionComponent={actionButtons}
              refreshMethod={getData}
            />
          </div>
        </div>
        {messages && messages.pageSize < messages.totalCount && (
          <CustomPagination
            currentPage={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            isNextPage={messages.hasNextPage}
            isPrevPage={messages.hasPreviousPage}
            total={messages.totalCount}
          />
        )}
      </div>
    </>
  );
};

export default AdminContactUs;
