import './AdminProductsCategories.scss';

import React, { useState } from 'react';

import AdminRemoveIcon from '../../../../general/assets/svg/AdminRemoveIcon';
import EditIconAction from '../../../../general/assets/svg/EditIconAction';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../general/services/products';
import { images } from '../../../../general/StaticData';
import { ICategory } from '../../../../general/types/types';
import CategoryModal from './CategoryModal';

interface CategoryProps {
  item: ICategory;
  updateData: () => Promise<any>;
}

const Category: React.FC<CategoryProps> = ({ item, updateData }) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  const openChangeModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const { mutate: deleteProductCategory } = useCustomMutation(
    createProductsService(adminInstance).deleteProductCategory,
    undefined,
    {
      onSuccess() {
        updateData();
      },
    },
  );

  const deleteCategory = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const categoryId = e.currentTarget.dataset.id;
    deleteProductCategory(categoryId as string);
  };

  return (
    <>
      <div className="page-section__category__info">
        <img src={item.imageUrl ? `${item.imageUrl}?${Math.random()}` : images.defaultImg} alt="" />
        <div className="page-section__category__name-settings">
          <div className="category__name__settings">
            <span>{item.name}</span>
          </div>
          <span className="page-section__category-id">id: {item.id}</span>
        </div>
      </div>
      <div className="page-section__category__action">
        <button onClick={openChangeModal} data-id={item.id}>
          <EditIconAction />
        </button>
        <button onClick={deleteCategory} data-id={item.id}>
          <AdminRemoveIcon />
        </button>
      </div>
      {isOpenModal && (
        <CategoryModal category={item} isModal={openChangeModal} updateData={updateData} />
      )}
    </>
  );
};

export default Category;
