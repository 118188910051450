import './BloggersAndCompanies.scss';

import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';

import { queryClient } from '../../../..';
import DeleteActionIcon from '../../../../general/assets/svg/DeleteActionIcon';
import EditIconAction from '../../../../general/assets/svg/EditIconAction';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import useKey, { keys } from '../../../../general/hooks/useKey';
import usePagination from '../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../general/queryKeys';
import { removeAffiliateById } from '../../../../general/redux/reducers/Affiliate-Reducer';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createRefferalService from '../../../../general/services/refferal';
import { Affiliate, PaggingObj, Refferal } from '../../../../general/types/types';
import Confirm from '../../../components/confirm/Confirm';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';
import AddAffiliate from './addAffiliate/AddAffiliate';

interface ActionButtonsProps {
  item: any;
  deleteMethod: (id: string) => void;
  editMethod: (id: string) => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ item, deleteMethod, editMethod }) => {
  const [deleteAffiliateAsk, setDeleteAffiliateAsk] = useState(false);

  useKey((event: KeyboardEvent) => {
    if (event.key === keys.ESCAPE) {
      deleteAffiliateAsk && setDeleteAffiliateAsk(false);
    }
  });

  return (
    <>
      <button
        onClick={() => editMethod(item.id)}
        className="table-block__tbody__change-item__button"
      >
        <EditIconAction />
      </button>
      <button
        onClick={() => setDeleteAffiliateAsk(true)}
        className="table-block__tbody__change-item__button"
      >
        <DeleteActionIcon />
      </button>
      {deleteAffiliateAsk && (
        <Confirm
          title="Delete confirm"
          text={'Are you sure you want to delete this affiliate?'}
          handler={setDeleteAffiliateAsk}
          clickEvent={() => {
            deleteMethod(item.id);
          }}
        />
      )}
    </>
  );
};

const BloggersAndCompanies: React.FC = () => {
  const dispatch = useDispatch();
  const [affiliates, setAffiliates] = useState<any[]>([]);

  const [modalAddAffiliates, setModalAddAffiliates] = useReducer((temp) => !temp, false);
  const headers = ['name', 'nickname', 'links to social media'];

  const [toEdit, setToEdit] = useState<any>();

  const { page, pageSize, setPage, setPageSize } = usePagination();

  const {
    data: affiliatesIds,
    isLoading: affiliatesLoading,
    refetch: getData,
  } = useCustomQuery<PaggingObj<Affiliate>>(queryKeys.REFFERAL_AFFILIATES, () =>
    createRefferalService(adminInstance).getAffiliates(page, pageSize, ''),
  );

  useEffect(() => {
    if (affiliatesIds) {
      const fetchAffiliatesInfo = async () => {
        const affiliates = affiliatesIds.items.map(async (item) => {
          if (item.id) {
            const response = await createRefferalService(adminInstance).getAffiliateInfo(item.id);
            return response.data;
          }
        });

        const completed = await Promise.all(affiliates);
        setAffiliates(completed);
      };

      fetchAffiliatesInfo();
    }
  }, [affiliatesIds, dispatch]);

  useEffect(() => {
    getData();
  }, [page, pageSize, getData]);

  const { mutate: removeAffiliate } = useCustomMutation(
    createRefferalService(adminInstance).deleteAffiliate,
    undefined,
    {
      onSuccess: (data: any) => {
        if (data.response !== undefined && data.response.data.detail) {
          getData();

          dispatch(setType(types.WARNING));
          dispatch(
            setInfo({
              n: types.WARNING,
              r: { s: data.response.data.detail },
            }),
          );
        }
        queryClient.refetchQueries(queryKeys.REFFERAL_AFFILIATES);
      },
    },
  );
  const { data: referrals } = useCustomQuery<PaggingObj<Refferal>>(queryKeys.REFFERAL_CODES, () =>
    createRefferalService(adminInstance).getRefferalPrograms(page, pageSize, ''),
  );

  useKey((event: KeyboardEvent) => {
    if (event.key === keys.ESCAPE) {
      modalAddAffiliates && setModalAddAffiliates();
    }
  });

  const handleEdit = (id: string) => {
    setModalAddAffiliates();
    setToEdit(affiliates.find((item) => item.id === id));
  };

  const handleRemove = (id: string) => {
    if (referrals && referrals.items && Array.isArray(referrals.items)) {
      const allRefs = referrals.items.flatMap((item: any) => item.affiliate.id);
      const affiliateToRemove = affiliates.find((affiliate) => affiliate.id === id);

      if (affiliateToRemove && allRefs.includes(affiliateToRemove.id)) {
        dispatch(setType(types.WARNING));
        dispatch(
          setInfo({
            n: types.WARNING,
            r: { s: "You can't delete an affiliate that has referral codes" },
          }),
        );

        return;
      }

      removeAffiliate(id);
      dispatch(removeAffiliateById(id));
    }
  };

  const actionButtons = (item: any, deleteMethod: any, refreshMethod: any) => (
    <ActionButtons item={item} deleteMethod={deleteMethod} editMethod={refreshMethod} />
  );

  return (
    <>
      {affiliatesLoading && (
        <div className="page-update-overlay">
          <Preloader />
        </div>
      )}
      <div className="affiliates">
        <CustomTable
          data={affiliates}
          nameOfAction={'edit'}
          headers={headers}
          refreshMethod={handleEdit}
          deleteMethod={handleRemove}
          isAction={true}
          actionComponent={actionButtons}
        />
      </div>
      {affiliatesIds && affiliatesIds.pageSize < affiliatesIds.totalCount && (
        <CustomPagination
          currentPage={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          isNextPage={affiliatesIds.hasNextPage}
          isPrevPage={affiliatesIds.hasPreviousPage}
          total={affiliatesIds.totalCount}
        />
      )}
      {modalAddAffiliates && (
        <div className="modal-view__add-affiliate">
          <AdminModalWindow title={'Add Affiliate'} isOpen={setModalAddAffiliates} mWidth={'50%'}>
            <AddAffiliate isEdit={true} item={toEdit} close={setModalAddAffiliates} />
          </AdminModalWindow>
        </div>
      )}
    </>
  );
};

export default BloggersAndCompanies;
