import './Contacts.scss';

import React, { ChangeEvent, useState } from 'react';

import LocationMarkerIcon from '../../../general/assets/svg/LocationMarkerIcon';
import MailIcon from '../../../general/assets/svg/MailIcon';
import PhoneIcon from '../../../general/assets/svg/PhoneIcon';
import Preloader, { preloaderStyles } from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../general/queryKeys';
import createContactMessagesService from '../../../general/services/contactMessages';
import createContactsService from '../../../general/services/contacts';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import { googleFeatures } from '../../../general/StaticData';
import { Contacts } from '../../../general/types/types';
import { regexp } from '../../../general/utils/Validations';
import NewMessage from '../../components/modals-window/NewMessage';

const ContactsPage: React.FC = () => {
  const [isPreloader, setIsPreloader] = useState(false);
  const [isNewMessage, setIsNewMessage] = useState(false);

  const sendMessage = async () => {
    setIsPreloader(true);
    const inputsBlock = document.querySelector('.contacts-section__form');
    const inputs = inputsBlock?.querySelectorAll('input') as NodeListOf<HTMLInputElement>;
    const textarea = inputsBlock?.querySelector('textarea') as HTMLTextAreaElement;

    const required: boolean[] = [];

    if (!regexp.string.test(inputs[0].value.trim())) {
      inputs[0].style.border = '1px solid red';
      required.push(false);
    }
    if (!regexp.string.test(inputs[1].value.trim())) {
      inputs[1].style.border = '1px solid red';
      required.push(false);
    }
    if (!regexp.email_regexp.test(inputs[2].value.trim())) {
      inputs[2].style.border = '1px solid red';
      required.push(false);
    }
    if (!regexp.phone_regexp.test(inputs[3].value.trim())) {
      inputs[3].style.border = '1px solid red';
      required.push(false);
    }

    if (required.includes(false)) {
      setIsPreloader(false);
      return;
    } else {
      const result = {
        name: inputs[0].value,
        email: inputs[2].value,
        companyName: inputs[1].value,
        phoneNumber: inputs[3].value,
        message: textarea.value,
        reason: '',
      };

      await createContactMessagesService(clientInstance).postMessage(result);
      setIsNewMessage(true);
      inputs.forEach((item) => {
        item.value = '';
      });
      textarea.value = '';
    }
    setIsPreloader(false);
  };

  const setDefaultInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.target.style.border = '1px solid #25A8CF';
  };
  const { data: contacts, isLoading: loadinContacts } = useCustomQuery<Contacts>(
    queryKeys.COMPANY_CONTACTS,
    () => createContactsService(clientInstance).getContacts(),
  );

  return (
    <section className="contacts-section page-container">
      {isNewMessage && <NewMessage isOpen={setIsNewMessage} />}
      <div className="contacts-section__info-form">
        <div className="contacts-section__block">
          <div className="contacts-section__title">Contact Us</div>
          <div className="contacts-section__contacts">
            <div className="column-contacts__item">
              <div className="column-contacts__icon">
                <LocationMarkerIcon fill="white" />
              </div>
              <div className="column-contacts__text">
                <span>Address: </span>
                <p className="column-contacts__text-p">{contacts?.addressWeb}</p>
              </div>
            </div>
            <div className="column-contacts__item">
              <div className="column-contacts__icon">
                <PhoneIcon />
              </div>
              <div className="column-contacts__text">
                <span>Phone: </span>
                <p className="column-contacts__text-p text__p">{contacts?.phoneWeb}</p>
              </div>
            </div>
            <div className="column-contacts__item">
              <div className="column-contacts__icon">
                <MailIcon />
              </div>
              <div className="column-contacts__text">
                <span>Email: </span>
                <p className="column-contacts__text-p text__p">{contacts?.emailWeb}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="contacts-section__block">
          <div className="contacts-section__title">Send us a message</div>
          <div className="contacts-section__form">
            <div className="contacts-section__form__inputs_row">
              <input type="text" placeholder="Name" onChange={setDefaultInput} />
              <input type="text" placeholder="Company Name" onChange={setDefaultInput} />
            </div>
            <div className="contacts-section__form__inputs_row">
              <input type="text" placeholder="Email" onChange={setDefaultInput} />
              <input type="number" placeholder="Contact Number" onChange={setDefaultInput} />
            </div>
            <textarea
              className="contacts-section__form__textarea"
              placeholder="Message"
              onChange={setDefaultInput}
            />
            <div className="contacts-section__form__btn">
              <button className=" section-btn-gradient" onClick={sendMessage}>
                {isPreloader || loadinContacts ? (
                  <Preloader style={preloaderStyles.DARK} />
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="contacts-sectino__content__map">
        <iframe
          title=" "
          src={`https://www.google.com/maps/embed?pb=${googleFeatures.mapPb}`}
          width="100%"
          lang="en"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
};

export default ContactsPage;
