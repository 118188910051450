import { AxiosInstance } from 'axios';

import { Staff } from '../types/types';

interface HomePageService {
  putMainPageProductsStatus: (data: {
    productId: string;
    isOnMainPage: boolean;
    mainPageOrder: number;
  }) => Promise<any>;
  deleteTestimoneal: (id: any) => Promise<any>;
  putTestimonealImage: (body: { id: string; image: File }) => Promise<any>;
  deleteTestimonealImage: (body: { imageId: string }) => Promise<any>;
  putTestimoneal: (body: {
    id: string;
    username: string;
    country: string;
    content: string;
    rating: string;
  }) => Promise<any>;
  postTestimoneal: (body: {
    name: string;
    country: string;
    content: string;
    rating: number;
    image: File;
  }) => Promise<any>;
  getVideoLink: () => Promise<any>;
  getShowcase: () => Promise<any>;
  getPartners: () => Promise<any>;
  getTestimonials: () => Promise<any>;
  getInfoBlocks: () => Promise<any>;
  postMainPageVideo: (data: any) => Promise<any>;
  getAllTestimoneals: () => Promise<any>;
  getTestimonealById: (id: string) => Promise<any>;
  createNewStaff: (body: Staff) => Promise<any>;
  deleteStaff: (id: string) => Promise<any>;
  updateStaff: (data: { id: string; body: StaffUpdate }) => Promise<any>;
  getCompanyPartnerById: (id: string) => Promise<any>;
  postCompanyPartner: (data: { name: string; url: string; image: any }) => Promise<any>;
  deleteCompanyPartner: (id: string) => Promise<any>;
  postCompanyPartnerImg: (data: { id: string; image: any }) => Promise<any>;
  putCompanyPartnerName: (data: { id: string; name: string; url: string }) => Promise<any>;
  getAllBlocks: () => Promise<any>;
  getInfoBlockByid: (id: string) => Promise<any>;
  postInfoBlock: (data: {
    title: string;
    btn_text: string;
    content: string;
    image: any;
    icon: any;
  }) => Promise<any>;
  putInfoBlock: (data: any) => Promise<any>;
  putInfoBlockImage: (data: { id: string; image: File }) => Promise<any>;
  putInfoBlockIcon: (data: { id: string; image: File }) => Promise<any>;
  deleteBlock: (id: string) => Promise<any>;
  getMainPageProducts: () => Promise<any>;
}

interface StaffUpdate {
  currentIMG: File | string;
  fullName: string;
  position: string;
  linkedIn: string;
  isFired: boolean;
}

const createHomePageService = (instance: AxiosInstance): HomePageService => ({
  async putMainPageProductsStatus(data: {
    productId: string;
    isOnMainPage: boolean;
    mainPageOrder: number;
  }) {
    try {
      const response = await instance.put(`/Product/mainPageStatus`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteTestimoneal(id: any) {
    const data = {
      id: id,
    };

    const response = await instance.delete(`/Testimonial`, { data });
    return response;
  },

  async putTestimonealImage(body: { id: string; image: File }) {
    const formData = new FormData();
    formData.append('image', body.image);

    const data = {
      image: body.image,
    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.post(
        `/Testimonial/updateImage?TestimonialId=${body.id}`,
        data,
        {
          headers,
        },
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteTestimonealImage(body: { imageId: string }) {
    try {
      const response = await instance.delete(`/Testimonial/removeImage`, {
        data: body,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async putTestimoneal(body: {
    id: string;
    username: string;
    country: string;
    content: string;
    rating: string;
  }) {
    try {
      const response = await instance.put(`/Testimonial`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postTestimoneal(body: {
    name: string;
    country: string;
    content: string;
    rating: number;
    image: File;
  }) {
    const formData = new FormData();
    formData.append('image', body.image);

    const data = {
      image: body.image,
    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.post(
        `/Testimonial?Username=${body.name}&Country=${body.country}&Content=${body.content}&Rating=${body.rating}`,
        data,
        { headers },
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getMainPageProducts() {
    try {
      const response = await instance.get(`/Product/mainPageAdmin`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteBlock(id: string) {
    const data = {
      id: id,
    };

    try {
      const response = await instance.delete(`/MainPageInfoBlock`, { data });
      return response;
    } catch (error) {
      return error;
    }
  },

  async putInfoBlockIcon(data: { id: string; image: File }) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.put(
        `/MainPageInfoBlock/icon?InfoBlockId=${data.id}`,
        {
          Icon: data.image,
        },
        {
          headers,
        },
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async putInfoBlockImage(data: { id: string; image: File }) {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.put(
        `/MainPageInfoBlock/image?InfoBlockId=${data.id}`,
        {
          Image: data.image,
        },
        {
          headers,
        },
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async putInfoBlock(data: any) {
    try {
      const response = await instance.put(`/MainPageInfoBlock`, data);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postInfoBlock(data: {
    title: string;
    btn_text: string;
    content: string;
    image: any;
    icon: any;
  }) {
    const formData = new FormData();
    formData.append('image', data.image);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.post(
        `MainPageInfoBlock?Title=${data.title}&Content=${data.content}&ButtonText=${data.btn_text}`,
        {
          Image: data.image,
          ButtonIcon: data.icon,
        },
        { headers },
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getInfoBlockByid(id: string) {
    try {
      const response = await instance.get(`/MainPageInfoBlock/byId?Id=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAllBlocks() {
    try {
      const response = await instance.get(`/MainPageInfoBlock/all`);
      return response;
    } catch (error) {
      return error;
    }
  },
  async putCompanyPartnerName(data: { id: string; name: string; url: string }) {
    try {
      const body = {
        id: data.id,
        name: data.name,
        url: data.url,
      };

      const response = await instance.put(`/CompanyPartner/update`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postCompanyPartnerImg(data: { id: string; image: any }) {
    try {
      const formData = new FormData();
      formData.append('image', data.image);

      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      const response = await instance.post(
        `/CompanyPartner/updateImage?PartnerId=${data.id}`,
        {
          image: data.image,
        },
        {
          headers,
        },
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteCompanyPartner(id: string) {
    try {
      const data = {
        id: id,
      };

      const response = await instance.delete(`/CompanyPartner/delete`, {
        data,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async postCompanyPartner(data: { name: string; url: string; image: any }) {
    try {
      const formData = new FormData();
      formData.append('image', data.image);

      const headers = {
        'Content-Type': 'multipart/form-data',
      };

      const response = await instance.post(
        `/CompanyPartner/create?Name=${data.name}&Url=${data.url}`,
        {
          image: data.image,
        },
        {
          headers,
        },
      );
      return response;
    } catch (error) {
      return error;
    }
  },

  async getCompanyPartnerById(id: string) {
    try {
      const response = await instance.get(`/CompanyPartner/getById?Id=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async updateStaff(data: { id: string; body: StaffUpdate }) {
    const formData = new FormData();
    formData.append('staff.staffId', data.id);
    if (data.body.currentIMG instanceof File) {
      formData.append('staff.staff.imageFile', data.body.currentIMG);
    } else {
      formData.append('staff.staff.imageUrl', data.body.currentIMG);
    }
    formData.append('staff.staff.fullName', data.body.fullName);
    formData.append('staff.staff.position', data.body.position);
    formData.append('staff.staff.linkedIn', data.body.linkedIn);
    formData.append('staff.staff.isFired', data.body.isFired.toString());

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.put('/Staff/update', formData, {
        headers,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async deleteStaff(id: string) {
    try {
      const response = await instance.delete(`/Staff/remove?staffId=${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async createNewStaff(body: Staff) {
    const formData = new FormData();
    formData.append('staff.imageFile', body.currentIMG);
    formData.append('staff.fullName', body.fullName);
    formData.append('staff.position', body.position);
    formData.append('staff.linkedIn', body.linkedIn);
    formData.append('staff.isFired', body.isFired.toString());

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.post('/Staff/create', formData, {
        headers,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getVideoLink() {
    try {
      const response = await instance.get(`/MainPageVideo`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postMainPageVideo(data: any) {
    const headers: any = {
      'Content-Type': 'multipart/form-data',
    };

    try {
      const response = await instance.post(`/MainPageVideo`, data, headers);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getShowcase() {
    try {
      const response = await instance.get(`/Product/mainPage`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getPartners() {
    try {
      const response = await instance.get(`/CompanyPartner/getAll`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getTestimonials() {
    try {
      const response = await instance.get(`/Testimonial`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getAllTestimoneals() {
    try {
      const response = await instance.get(`/Testimonial/full`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getTestimonealById(id: string) {
    try {
      const response = await instance.get(`/Testimonial/byId?Id=${id}`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async getInfoBlocks() {
    try {
      const response = await instance.get(`/MainPageInfoBlock/all`);
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createHomePageService;
