import './AdminProductsCategories.scss';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo } from '../../../../general/redux/reducers/Error-Reducer';
import { adminInstance, clientInstance } from '../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../general/services/products';
import { ICategory } from '../../../../general/types/types';
import Category from './Category';
import CategoryModal from './CategoryModal';

const AdminProductsCategories: React.FC = () => {
  const dispatch = useDispatch();
  const [isCreateModal, setIsCreateModal] = useState<boolean>(false);

  const { data: categories, refetch: getCategories } = useCustomQuery<ICategory[]>(
    queryKeys.PRODUCT_CATEGORIES,
    () => createProductsService(clientInstance).getProductsCategories(),
  );

  const { mutate: postMainGeneralToCategory } = useCustomMutation(
    createProductsService(adminInstance).postMainGeneralToCategory,
  );

  const openCreateModal = () => {
    setIsCreateModal(!isCreateModal);
  };

  const addNewCharacteristics = async (id: string) => {
    const section = document.querySelector('.inputs__text__characteristics__section');
    if (!section) return;
    const inputs = section.querySelectorAll<HTMLInputElement>('input');

    inputs.forEach((item) => {
      postMainGeneralToCategory({
        categoryId: id,
        name: item.value.trim(),
      });
    });
  };

  const saveCategory = async () => {
    const input = document.getElementById('open-modal') as HTMLInputElement;
    const image = document.getElementById('upload-photo') as HTMLInputElement;
    const label = document.querySelector('.img-loader') as HTMLElement;
    const mining = document.getElementById('Category_mining') as HTMLInputElement;

    if (image.files?.length === 0) {
      label.style.border = '1px solid red';
    } else {
      label.style.border = '1px solid transparent';
    }

    if (input.value.trim() === '') {
      input.style.border = '1px solid red';
    } else {
      input.style.border = '1px solid transparent';
    }

    if (input.value.trim() !== '' && image.files?.length !== 0) {
      const formData = new FormData();

      if (!image.files) {
        return;
      }

      formData.append('Image', image.files[0]);

      const { data } = await createProductsService(adminInstance).postProductCategory({
        image: formData,
        category: input.value,
        minig: mining.checked,
      });

      if (data.name === 'AxiosError') {
        dispatch(
          setInfo({
            n: data.message,
            r: Object.prototype.hasOwnProperty.call(data, 'response')
              ? { d: data.response.data, s: data.response.statusText }
              : '',
          }),
        );
        return;
      }

      if (!mining.checked) {
        await addNewCharacteristics(data.toString());
      }
      getCategories();
      openCreateModal();
    }
  };

  return (
    <>
      <div className="page-section__content">
        <div className="page-section__buttons">
          <button onClick={openCreateModal}>
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 8.5H15.28" stroke="#FFF9F9" strokeWidth="2" strokeMiterlimit="10" />
              <path
                d="M7.63989 0.859863V16.1399"
                stroke="#FFF9F9"
                strokeWidth="2"
                strokeMiterlimit="10"
              />
            </svg>
            <span>Categories</span>
          </button>
        </div>
        <div className="page-section-categories">
          <div className="page-section__categories">
            <div className="page-section__categories__title">All categories</div>
            {categories === null ? (
              <div className="preloader-block">
                <Preloader />
              </div>
            ) : (
              <ul>
                {categories && categories.length === 0 ? (
                  <li>NO DATA</li>
                ) : (
                  categories &&
                  categories.map((category) => (
                    <li key={category.id}>
                      <Category item={category} updateData={getCategories} />
                    </li>
                  ))
                )}
              </ul>
            )}
          </div>
        </div>
        {isCreateModal && (
          <CategoryModal
            isModal={openCreateModal}
            updateData={getCategories}
            method={saveCategory}
          />
        )}
      </div>
    </>
  );
};

export default AdminProductsCategories;
