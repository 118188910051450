import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CloseModalCrossIcon from '../../../../../general/assets/svg/CloseModalCrossIcon';
import LocationProdStoresIcon from '../../../../../general/assets/svg/LocationProdStoresIcon';
import Preloader from '../../../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../../general/queryKeys';
import {
  addChoosenStore,
  removeChoosenStore,
  resetChoosenStores,
} from '../../../../../general/redux/reducers/Product-Reducer';
import { RootState } from '../../../../../general/redux/store';
import { routesAdmin } from '../../../../../general/routes';
import { adminInstance } from '../../../../../general/services/main/axiosInstances';
import createProductsService from '../../../../../general/services/products';
import createShippingService from '../../../../../general/services/shipping';
import {
  Characteristic,
  ICategory,
  Location,
  ProductsStoreAddress,
} from '../../../../../general/types/types';

interface MainCharacteristicsProps {
  category: ICategory;
  isEdit: boolean;
  editProduct: any;
}

const MainCharacteristics: React.FC<MainCharacteristicsProps> = ({
  category,
  isEdit,
  editProduct,
}) => {
  const dispatch = useDispatch();
  const stores = useSelector((state: RootState) => state.product.choosenStores);
  const [list, setList] = useState<Characteristic[]>([]);

  //#region country location
  const { data: availableLocations } = useCustomQuery<Location[]>(
    queryKeys.AVAILABLE_PROD_LOCATIONS,
    () => createShippingService(adminInstance).getAvailableProdLocations(),
  );

  const [selectedCountry, setSelectedCountry] = useState(() =>
    isEdit && editProduct?.countryLocation ? editProduct.countryLocation : '',
  );

  const handleCountryChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newCountry = event.target.value;
    if (newCountry === 'link') {
      window.open(routesAdmin.adminPanel.path + routesAdmin.shippingInfo.path, '_blank');
    } else {
      setSelectedCountry(newCountry);
    }
  };

  const handleRemoveCountry = () => {
    setSelectedCountry('');
  };

  useEffect(() => {
    if (isEdit && editProduct?.countryLocation) {
      setSelectedCountry(editProduct.countryLocation);
    }
  }, [isEdit, editProduct]);
  //#endregion

  const { data: addresses } = useCustomQuery<ProductsStoreAddress[]>(
    queryKeys.AVAILABLE_STORES_LOCATIONS,
    () => createShippingService(adminInstance).getProductsStores(100, 1, ''),
    (data: any) => {
      return data?.items.filter((item: ProductsStoreAddress) => item.status);
    },
  );

  const { isLoading: mainCharacteristicsLoading, refetch: getMainCharacteristics } = useCustomQuery<
    Characteristic[]
  >(
    queryKeys.PRODUCTS_MAIN_CHARACTERISTICS,
    () => createProductsService(adminInstance).getMainGeneralOfProduct(editProduct?.id),
    undefined,
    undefined,
    {
      onSuccess: (data) => {
        if (data) setList(data);
      },
    },
  );

  const { isLoading: mainNamesLoading, refetch: getMainNames } = useCustomQuery<Characteristic[]>(
    queryKeys.PRODUCTS_MAIN_NAMES,
    () => createProductsService(adminInstance).getMainGeneralNames(category?.id),
    undefined,
    undefined,
    {
      onSuccess: (data) => {
        if (data) setList(data);
      },
    },
  );

  useEffect(() => {
    if (isEdit) {
      getMainCharacteristics();
    } else {
      getMainNames();
    }

    return () => {
      dispatch(resetChoosenStores());
    };
  }, [isEdit, getMainCharacteristics, getMainNames, dispatch]);

  const handleStoreClickAdd = (id: string) => {
    const store = addresses?.find((el) => el.id === id);
    if (store) {
      dispatch(addChoosenStore(store));
    }
  };

  const handleStoreClickRemove = (id: string) => {
    const store = addresses?.find((el) => el.id === id);
    if (store) {
      dispatch(removeChoosenStore(id));
    }
  };

  return (
    <div className="page-section__characteristics__content">
      <div className="page-section__characteristics__title">Main characteristics</div>
      <div className="page-section__characteristics__characteristic">
        {mainCharacteristicsLoading || mainNamesLoading ? (
          <>
            <Preloader />
          </>
        ) : (
          <>
            {category?.areMiningCharacteristics ? (
              <>
                <div className="characteristic__block">
                  <div className="characteristic__name">
                    Manufacturer <span>*</span>
                  </div>
                  <div className="characteristicc__value">
                    <input
                      placeholder="value"
                      defaultValue={isEdit === true ? editProduct?.manufacturer : ''}
                    />
                  </div>
                </div>
                <div className="characteristic__block">
                  <div className="characteristic__name">
                    Condition <span>*</span>
                  </div>
                  <div className="characteristicc__value">
                    <input
                      placeholder="value"
                      defaultValue={isEdit === true ? editProduct?.condition : ''}
                    />
                  </div>
                </div>
                <div className="characteristic__block">
                  <div className="characteristic__name">
                    Algorithm <span>*</span>
                  </div>
                  <div className="characteristicc__value">
                    <input
                      placeholder="value"
                      defaultValue={isEdit === true ? editProduct?.algorithm : ''}
                    />
                  </div>
                </div>
                <div className="characteristic__block">
                  <div className="characteristic__name">
                    Hashrate <span>*</span>
                  </div>
                  <div className="characteristicc__value hashrate-input-select">
                    <input
                      placeholder="number"
                      type="number"
                      defaultValue={isEdit === true ? editProduct?.hashrate?.split(' ')[0] : ''}
                    />
                    <div className="hashrate-input-select__select">
                      <select
                        className="hashrate-select"
                        defaultValue={editProduct?.hashrate?.split(' ')[1] || 'Ksol/s'}
                      >
                        <option value={'Ksol/s'}>Ksol/s</option>
                        <option value={'MH/s'}>MH/s</option>
                        <option value={'GH/s'}>GH/s</option>
                        <option value={'TH/s'}>TH/s</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="characteristic__block">
                  <div className="characteristic__name">
                    Power consumption (W) <span>*</span>
                  </div>
                  <div className="characteristicc__value">
                    <input
                      placeholder="number"
                      type="number"
                      defaultValue={isEdit === true ? editProduct?.energyConsumption : ''}
                    />
                  </div>
                </div>
                <div className="characteristic__block">
                  <div className="characteristic__name">
                    Energy efficiency (J/TH) <span>*</span>
                  </div>
                  <div className="characteristicc__value">
                    <input
                      placeholder="number"
                      type="number"
                      defaultValue={isEdit === true ? editProduct?.energyEfficiency : ''}
                    />
                  </div>
                </div>
                <div className="characteristic__block">
                  <div className="characteristic__name">
                    Country location <span>*</span>
                  </div>
                  <div className="characteristicc__value country-location">
                    <input placeholder="value" disabled value={selectedCountry} />
                    <button className="remove-last-country" onClick={handleRemoveCountry}>
                      <CloseModalCrossIcon />
                    </button>
                    <div className="location-select__select">
                      <select value={selectedCountry} onChange={handleCountryChange}>
                        <option value="" disabled hidden>
                          Select a store
                        </option>
                        {availableLocations?.map((country) => (
                          <option key={country.id} value={country.country}>
                            {country.country}
                          </option>
                        ))}
                        <option value="link">Add new country</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="stores__block">
                  <div className="stores__name header">
                    Stores <span>*</span>
                  </div>
                  <div className="stores">
                    <div className="store_block">
                      <span>Client stores</span>
                      <div className="choosenStores">
                        {stores.length > 0 ? (
                          <>
                            {stores.map((store, index) => (
                              <div
                                key={index}
                                className="store"
                                onClick={() => handleStoreClickRemove(store.id)}
                              >
                                <LocationProdStoresIcon />
                                <div className="info-block">
                                  <span className="country">{store.country}</span>
                                  <span className="address">{store.address}</span>
                                </div>
                              </div>
                            ))}
                          </>
                        ) : (
                          <span className="empty">Empty list</span>
                        )}
                      </div>
                    </div>
                    <div className="store_block">
                      <span>Available stores</span>
                      <div className="notChoosenStores">
                        {addresses &&
                        addresses?.length > 0 &&
                        stores.length !== addresses?.length ? (
                          <>
                            {addresses?.map((store, index) => {
                              const isStoreNotChosen = !stores.find((el) => el.id === store.id);
                              if (isStoreNotChosen) {
                                return (
                                  <div
                                    key={index}
                                    className="store"
                                    onClick={() => handleStoreClickAdd(store.id)}
                                  >
                                    <LocationProdStoresIcon />
                                    <div className="info-block">
                                      <span className="country">{store.country}</span>
                                      <span className="address">{store.address}</span>
                                    </div>
                                  </div>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </>
                        ) : (
                          <span className="empty">Empty list</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {list.map((item, index) => (
                  <div key={index} className="characteristic__block">
                    <div className="characteristic__name">
                      {item.name} <span>*</span>
                    </div>
                    <div className="characteristicc__value">
                      <input
                        data-id={item.id}
                        placeholder="value"
                        defaultValue={isEdit === true ? item?.value : ''}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default MainCharacteristics;
