import './AdminList.scss';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Preloader, { preloaderStyles } from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import { queryKeys } from '../../../../general/queryKeys';
import { setInfo, setType, types } from '../../../../general/redux/reducers/Error-Reducer';
import createAdminsService from '../../../../general/services/admins';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import AdminModalWindow from '../../../components/modal-window/ModalWindow';
import RoleSelector from './RoleSelector';

interface AdminModalProps {
  isEdit?: boolean;
  isOpen: (isOpen: boolean) => void;
  admin?: any;
  refreshMethod: () => void;
}

const AdminModal: React.FC<AdminModalProps> = ({
  isEdit = false,
  isOpen,
  admin,
  refreshMethod,
}) => {
  const dispatch = useDispatch();

  const [newPassword, setNewPassword] = useState<string | null>(null);

  const { data: roles } = useCustomQuery<string[]>(queryKeys.ALL_ROLES, () =>
    createAdminsService(adminInstance).getAllRoles(),
  );

  const { mutate: createAdmin, isLoading: creating } = useCustomMutation(
    createAdminsService(adminInstance).postNewAdmin,
    undefined,
    {
      onSuccess() {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Admin was successfully created' },
          }),
        );

        isOpen(false);
        refreshMethod();
      },
    },
  );

  const { mutate: putAdmin, isLoading: updating } = useCustomMutation(
    createAdminsService(adminInstance).putAdmin,
    undefined,
    {
      onSuccess(data: any) {
        if (data.status === 403) {
          dispatch(setType(types.ERROR));
          dispatch(
            setInfo({
              n: types.ERROR,
              r: { s: 'You can not change info becouse you are not owner' },
            }),
          );
        } else {
          dispatch(setType(types.SUCCESS));
          dispatch(
            setInfo({
              n: types.SUCCESS,
              r: { s: 'Admin was successfully updated' },
            }),
          );
        }

        isOpen(false);
        refreshMethod();
      },
    },
  );

  const { mutateAsync: updateAdminPassword, isLoading: updatingPassword } = useCustomMutation(
    createAdminsService(adminInstance).resetPasswordByOwner,
    undefined,
    {
      onSuccess() {
        dispatch(setType(types.SUCCESS));
        dispatch(
          setInfo({
            n: types.SUCCESS,
            r: { s: 'Admin password was successfully restored' },
          }),
        );

        refreshMethod();
      },
    },
  );

  const addAdmin = async () => {
    const blockInputs = document.querySelector('.modal-window-action__modal__user-admin__text');
    if (blockInputs) {
      const inputs = blockInputs.querySelectorAll('input');
      const role = document.querySelector('.role-payment-status__current-options__name')?.innerHTML;

      const required: boolean[] = [];

      if (inputs[0].value.trim() === '') {
        inputs[0].style.border = '1px solid red';
        required.push(false);
      }
      if (inputs[1].value.trim() === '') {
        inputs[1].style.border = '1px solid red';
        required.push(false);
      }
      if (inputs[2].value.trim() === '') {
        inputs[2].style.border = '1px solid red';
        required.push(false);
      }
      if (inputs[3].value.trim() === '') {
        inputs[3].style.border = '1px solid red';
        required.push(false);
      }
      if (inputs[4].value.trim() === '') {
        inputs[4].style.border = '1px solid red';
        required.push(false);
      }

      if (required.includes(false)) return;

      const result = {
        username: inputs[0].value.trim(),
        password: inputs[1].value.trim(),
        name: inputs[2].value.trim() === '' ? null : inputs[2].value.trim(),
        surname: inputs[3].value.trim() === '' ? null : inputs[3].value.trim(),
        email: inputs[4].value.trim() === '' ? null : inputs[4].value.trim(),
        role: role,
      };

      createAdmin(result);
    }
  };

  const updateAdmin = async () => {
    const blockInputs = document.querySelector('.modal-window-action__modal__user-admin__text');
    if (blockInputs) {
      const inputs = blockInputs.querySelectorAll('input');

      const required: boolean[] = [];

      if (inputs[0].value.trim() === '') {
        inputs[0].style.border = '1px solid red';
        required.push(false);
      }

      if (required.includes(false)) return;

      const result = {
        adminId: admin.id,
        username: inputs[0].value.trim(),
        name: inputs[1].value.trim() === '' ? null : inputs[1].value.trim(),
        surname: inputs[2].value.trim() === '' ? null : inputs[2].value.trim(),
        email: inputs[3].value.trim() === '' ? null : inputs[3].value.trim(),
      };

      putAdmin(result);
    }
  };

  const updatePassword = async () => {
    const newPassword: any = await updateAdminPassword(admin.id);
    setNewPassword(newPassword);
  };

  return (
    <AdminModalWindow isOpen={isOpen} title={`${isEdit === true ? 'Edit' : 'Create'} Admin`}>
      {(creating || updating) && (
        <div className="modal-window-action__modal__home__loading">
          <Preloader />
        </div>
      )}
      <div className="modal-window-action__modal__user-admin">
        <div className="modal-window-action__modal__user-admin__text">
          <div className="modal-window-action__modal__user-admin__required">
            <input
              type="text"
              placeholder="login (username)"
              defaultValue={admin?.userName || ''}
            />
            {isEdit ? (
              <>
                {newPassword === null ? (
                  <button className="section-btn-gradient" onClick={updatePassword}>
                    {updatingPassword ? (
                      <>
                        <Preloader style={preloaderStyles.DARK} />
                      </>
                    ) : (
                      'Get new password'
                    )}
                  </button>
                ) : (
                  <span className="span__new-password">{newPassword}</span>
                )}
              </>
            ) : (
              <>
                <input type="password" placeholder="password" />
              </>
            )}
          </div>
          <div className="modal-window-action__modal__user-admin__no-required">
            <input type="text" placeholder="name" defaultValue={admin?.name || ''} />
            <input type="text" placeholder="surname" defaultValue={admin?.surname || ''} />
            <input type="text" placeholder="email" defaultValue={admin?.email || ''} />
          </div>
        </div>
        {roles && <RoleSelector roles={roles} currentStatus={admin?.role || 'Administrator'} />}
      </div>
      <div className="modal-window-action__modal__button">
        <button className="section-btn-gradient" onClick={isEdit ? updateAdmin : addAdmin}>
          {isEdit ? 'Save' : 'Add'}
        </button>
      </div>
    </AdminModalWindow>
  );
};

export default AdminModal;
