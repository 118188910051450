import './AdminMessagesList.scss';

import React, { Fragment, useEffect, useState } from 'react';

import AdminMailIcon from '../../../../general/assets/svg/AdminMailIcon';
import CloseBig from '../../../../general/assets/svg/CloseBig';
import DeleteActionIcon from '../../../../general/assets/svg/DeleteActionIcon';
import Preloader from '../../../../general/components/preloader/Preloader';
import useCustomMutation from '../../../../general/hooks/useCustomMutation';
import useCustomQuery from '../../../../general/hooks/useCustomQuerry';
import usePagination from '../../../../general/hooks/usePagination';
import { queryKeys } from '../../../../general/queryKeys';
import { adminInstance } from '../../../../general/services/main/axiosInstances';
import createMessagesService from '../../../../general/services/messages';
import { Message, PaggingObj } from '../../../../general/types/types';
import CustomPagination from '../../../components/tables/custom-pagination/CustomPagination';
import CustomTable from '../../../components/tables/custom-table/CustomTable';

interface ModalMessageProps {
  isActive: (active: boolean) => void;
  message: Message;
  refreshMethod: () => void;
}

const ModalMessage: React.FC<ModalMessageProps> = ({ isActive, message, refreshMethod }) => {
  const [isPreloader, setIsPreloader] = useState(false);
  const closeModal = () => {
    isActive(false);
  };

  const { mutate: putMessageStatus } = useCustomMutation(
    createMessagesService(adminInstance).putMessageStatus,
  );

  const confirmMessage = async () => {
    setIsPreloader(true);
    putMessageStatus({
      id: message.id,
      isProcessed: !message.isProcessed,
    });
    refreshMethod();
    closeModal();
    setIsPreloader(false);
  };

  return (
    <div className="modal-window-action">
      <div className="modal-window-action__overlay" onClick={closeModal} />
      <div className="modal-window-action__modal__messages" style={{ width: 700 }}>
        {isPreloader && (
          <div className="modal-window-action__modal__home__loading">
            <Preloader />
          </div>
        )}
        <div className="modal-window-action__modal__title">
          <span>
            Message from{' '}
            <span style={{ fontWeight: 500, color: '#25A8CF' }}>
              {new Date(message.createdAt).toLocaleString().split('/').join('.')}
            </span>
          </span>
          <button onClick={closeModal}>
            <CloseBig />
          </button>
        </div>
        <div className="modal-window-action__modal__message">
          <div className="modal-window-action__modal__message__info">
            <div className="modal-window-action__modal__message__info__row">
              <div className="modal-window-action__modal__message__info-block">
                <span className="message__info-block__label">Name: </span>
                <span className="message__info-block__text">{message.name}</span>
              </div>
              <div className="modal-window-action__modal__message__info-block">
                <span className="message__info-block__label">Company name: </span>
                <span className="message__info-block__text">{message.companyName}</span>
              </div>
            </div>
            <div className="modal-window-action__modal__message__info__row">
              <div className="modal-window-action__modal__message__info-block">
                <span className="message__info-block__label">Phone: </span>
                <span className="message__info-block__text">{message.phoneNumber}</span>
              </div>
              <div className="modal-window-action__modal__message__info-block">
                <span className="message__info-block__label">Email: </span>
                <span className="message__info-block__text">{message.email}</span>
              </div>
            </div>
          </div>
          <div className="modal-window-action__modal__message__text">
            <div>
              {message.message.split('\n').map((string, index) => (
                <Fragment key={index}>
                  {string}
                  <br />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className="modal-window-action__modal__button">
          <button className="section-btn-gradient" onClick={confirmMessage}>
            {message.isProcessed ? 'Reject' : 'Confirm'}
          </button>
        </div>
      </div>
    </div>
  );
};

interface ActionButtonsProps {
  item: Message;
  deleteMethod?: () => void;
  refreshMethod?: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ item, deleteMethod, refreshMethod }) => {
  const [isModalMessage, setIsModalMessage] = useState(false);

  const openModal = () => {
    setIsModalMessage(true);
  };

  return (
    <>
      {isModalMessage && refreshMethod && (
        <ModalMessage isActive={setIsModalMessage} message={item} refreshMethod={refreshMethod} />
      )}
      <button
        style={{ width: 'auto' }}
        onClick={openModal}
        className="table-block__tbody__change-item__button"
      >
        <AdminMailIcon />
      </button>
      <button
        data-id={item.id}
        onClick={deleteMethod}
        className="table-block__tbody__change-item__button"
      >
        <DeleteActionIcon />
      </button>
    </>
  );
};

const AdminMessagesList: React.FC = () => {
  const { page, pageSize, setPage, setPageSize } = usePagination();

  const {
    data: messages,
    isLoading: messagesLoading,
    refetch: getData,
  } = useCustomQuery<PaggingObj<Message>>(queryKeys.MESSAGES_ADMIN, () =>
    createMessagesService(adminInstance).getMessage(pageSize, page),
  );

  const { mutate: deleteMessage } = useCustomMutation(
    createMessagesService(adminInstance).deleteMessage,
  );

  useEffect(() => {
    getData();
  }, [page, pageSize, getData]);

  const actionButtons = (
    item: Message,
    deleteMethod?: (item?: Message) => void,
    refreshMethod?: (item?: Message) => void,
  ): React.ReactNode => (
    <ActionButtons item={item} deleteMethod={deleteMethod} refreshMethod={refreshMethod} />
  );

  const deleteItem = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = e.currentTarget.dataset.id;
    if (id) {
      deleteMessage(id);
      getData();
    }
  };

  return (
    <>
      <div className="page-section__content">
        <div className="page-section__buttons"></div>
        <div className="page-section__content__list-section">
          <div className="list-section__content">
            {messagesLoading && (
              <div className="page-update-overlay">
                <Preloader />
              </div>
            )}
            <CustomTable
              data={messages?.items || []}
              headers={[
                'user-name',
                'company-name',
                'phone',
                'email',
                'message-status',
                'Reason for Contact',
              ]}
              deleteMethod={deleteItem}
              actionComponent={actionButtons}
              refreshMethod={getData}
            />
          </div>
        </div>
        {messages && messages.pageSize < messages.totalCount && (
          <CustomPagination
            currentPage={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            isNextPage={messages.hasNextPage}
            isPrevPage={messages.hasPreviousPage}
            total={messages.totalCount}
          />
        )}
      </div>
    </>
  );
};

export default AdminMessagesList;
