import React, { useState } from 'react';

import AdminRemoveIcon from '../../../../general/assets/svg/AdminRemoveIcon';
import PlusIcon from '../../../../general/assets/svg/PlusIcon';

interface Characteristic {
  id: number;
  name?: string;
}

interface CategoriesCharacteristicsProps {
  characteristics: Characteristic[];
  removedCharacteristic: number[]; // Array to store removed characteristic ids
}

const CategoriesCharacteristics: React.FC<CategoriesCharacteristicsProps> = ({
  characteristics,
  removedCharacteristic,
}) => {
  const [itemList, setItemList] = useState<Characteristic[]>([...characteristics]);

  const addCharacteristic = () => {
    const id =
      itemList[itemList.length - 1]?.id === undefined ? 0 : itemList[itemList.length - 1].id + 1;
    setItemList((prevComponents) => [...prevComponents, { id }]);
  };

  const deleteCharacteristic = (e: React.MouseEvent<HTMLButtonElement>, id: number) => {
    removedCharacteristic.push(id);
    setItemList((prevComponents) => prevComponents.filter((component) => component.id !== id));
    e.stopPropagation();
  };

  return (
    <div className="modal-window-action__modal__inputs__text__characteristics">
      <div className="inputs__text__characteristics__top">
        <span>Characteristics</span>
        <button onClick={addCharacteristic}>
          <PlusIcon />
        </button>
      </div>
      <div className="inputs__text__characteristics__section">
        {itemList.map((item) => (
          <div key={item.id} className="characteristics__section__input-block">
            <input
              data-id={item.id}
              type="text"
              placeholder="name"
              defaultValue={item?.name || ''}
              disabled={item?.name !== undefined}
            />
            <button onClick={(e) => deleteCharacteristic(e, item.id)}>
              <AdminRemoveIcon />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoriesCharacteristics;
