import React from 'react';

const PlusIcon: React.FC<{ stroke?: string }> = ({ stroke }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 12H24"
      stroke={`${!stroke ? '#25A8CF' : stroke}`}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M12 0V24"
      stroke={`${!stroke ? '#25A8CF' : stroke}`}
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default PlusIcon;
