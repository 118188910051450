import './Product.scss';

import { useGoogleOneTapLogin } from '@react-oauth/google';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, MouseEvent, MouseEventHandler, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CartProdIcon from '../../../general/assets/svg/CartProdIcon';
import MailIcon from '../../../general/assets/svg/MailIcon';
import MarkerDoneIcon from '../../../general/assets/svg/MarkerDoneIcon';
import PhoneIcon from '../../../general/assets/svg/PhoneIcon';
import Preloader from '../../../general/components/preloader/Preloader';
import useCustomQuery from '../../../general/hooks/useCustomQuerry';
import useGoogleCredentials from '../../../general/hooks/useGoogleCredentials';
import { queryKeys } from '../../../general/queryKeys';
import { addCartItemWithCount } from '../../../general/redux/reducers/ClientCart-Reducer';
import { setInfo, setType, types } from '../../../general/redux/reducers/Error-Reducer';
import { RootState } from '../../../general/redux/store';
import { routesClient } from '../../../general/routes';
import createContactsService from '../../../general/services/contacts';
import { clientInstance } from '../../../general/services/main/axiosInstances';
import createProductsService from '../../../general/services/products';
import { environment, environments, images } from '../../../general/StaticData';
import { Contacts, ICategory, ProductMax } from '../../../general/types/types';
import { createCredentials } from '../../components/authorization/google-authorization/GoogleAuth';
import ModalWindow from '../../components/authorization/modal-window/ModalWindow';
import WishlistBtn from '../../components/buttons/wishlist/WishlistBtn';
import ProductSlider from '../../components/sliders/product-slider/ProductSlider';
import Features from './Features';
import Tabs from './Tabs';

const miningCharacteristics = [
  {
    name: 'Condition',
    key: 'condition',
  },
  {
    name: 'Algorithm',
    key: 'algorithm',
  },
  {
    name: 'Hashrate',
    key: 'hashrate',
  },
  {
    name: 'Energy efficiency',
    key: 'energyEfficiency',
  },
  {
    name: 'Power Consumption',
    key: 'energyConsumption',
  },
  {
    name: 'Country',
    key: 'countryLocation',
  },
];

const service = [
  'Direct Communication: Personalized, one-on-one support.',
  'Technical Expertise: Solutions for all tech needs.',
  'Profit Analysis: Tailored financial insights for each buyer.',
  'Secure Shopping: Guaranteed buyer protection.',
  'Quality Hardware: Pre-tested for optimal performance.',
  'User-Friendly Interface: No setup, ready to go.',
  'Hosting Options: Top-tier choices from our partners.',
  'Pool Access: Exclusive terms for our clients.',
];

const exceptThisSymbols = ['e', 'E', '+', '-', '.'];

const Product: React.FC = () => {
  const { url } = useParams<{ url: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.clps.cp2);
  const cart = useSelector((state: RootState) => state.pc.pc); // Adjust any to your actual Redux state structure
  const setGoogleCredentials = useGoogleCredentials();

  const [count, setCount] = useState<number>(1);

  const [mainCharacteristics, setMainCharacteristics] = useState<any[]>([]);
  const [isQuiuckOrder, setIsQuickOrder] = useState<boolean>(false);

  const [isAuth, setIsAuth] = useState<boolean>(false);

  useEffect(() => {
    if (isQuiuckOrder && userId) {
      navigate(routesClient.cartInformation.path);
    }
  }, [isAuth, isQuiuckOrder, userId, navigate]);

  const { enqueueSnackbar } = useSnackbar();
  const [isSnackbarActive, setIsSnackbarActive] = useState<boolean>(false);

  const { data: contacts } = useCustomQuery<Contacts>(queryKeys.COMPANY_CONTACTS, () =>
    createContactsService(clientInstance).getContacts(),
  );

  const { data: product } = useCustomQuery<ProductMax>(
    queryKeys.PRODUCT_CLIENT,
    () => createProductsService(clientInstance).getProductByURL(url as string),
    undefined,
    undefined,
    {
      keepPreviousData: false,
      onError: (data) => {
        navigate('/404');
        return;
      },
      onSuccess: (data: any) => {
        if (data.status === 204) {
          navigate('/404');
        }
      },
    },
  );

  const { data: currentCategory, refetch: getCurrentCategory } = useCustomQuery<ICategory>(
    queryKeys.PRODUCT_CATEGORY_CUERRENT,
    () => createProductsService(clientInstance).getProductsCategories(),
    (data: any) => {
      return data?.find((item: any) => item.id === product?.categoryId);
    },
    undefined,
    {
      enabled: !!product,
    },
  );

  const { refetch: getMainCharacteristics } = useCustomQuery<any>(
    queryKeys.PRODUCT_CLIENT_MAIN_CHARACTERISTICS,
    () =>
      createProductsService(clientInstance).getCharacteristicsMainGeneralOfProduct(
        product?.id as string,
      ),
    undefined,
    undefined,
    {
      enabled: false,
      onSuccess(data) {
        setMainCharacteristics(data);
      },
    },
  );

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      setGoogleCredentials(createCredentials(credentialResponse));
    },
    cancel_on_tap_outside: false,
    disabled: userId !== '',
    use_fedcm_for_prompt:
      environment === environments.DEV_SITE || environment === environments.PROD,
    onError: () => {
      dispatch(setType(types.ERROR));
      dispatch(
        setInfo({
          n: types.ERROR,
          r: { s: 'Ooops... Login Failed' },
        }),
      );
    },
  });

  useEffect(() => {
    if (product) {
      getCurrentCategory();
    }
  }, [product, getCurrentCategory]);

  useEffect(() => {
    if (product && currentCategory?.areMiningCharacteristics !== undefined) {
      if (currentCategory?.areMiningCharacteristics) {
        setMainCharacteristics(miningCharacteristics);
      } else {
        getMainCharacteristics();
      }
    }
  }, [product, currentCategory?.areMiningCharacteristics, getMainCharacteristics]);

  const setDefaultCount = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.trim() === '' || +e.target.value === 0) {
      setCount(1);
    }
  };

  const changeCount: MouseEventHandler<HTMLButtonElement> = (e) => {
    const countsCountElement = document.querySelector(
      '.counts-buttons__counts-count',
    ) as HTMLInputElement | null;

    if (countsCountElement) {
      if (e.currentTarget.hasAttribute('data-plus') && count < 999) {
        countsCountElement.value = `${count + 1}`;
        setCount(count + 1);
      }
      if (e.currentTarget.hasAttribute('data-minus') && count >= 2) {
        countsCountElement.value = `${count - 1}`;
        setCount(count - 1);
      }
    }

    e.stopPropagation();
  };

  const changeCountInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    if (value > 999) {
      setCount(999);
    } else if (value >= 1) {
      setCount(value);
    }
  };

  const changeImg = (e: MouseEvent<HTMLImageElement>) => {
    const current_img = document.getElementById('product-current-img') as HTMLImageElement;
    if (current_img) {
      current_img.src = e.currentTarget.dataset.url || '';
    }
    e.stopPropagation();
  };

  const showSnackbar = (message: string, options: any) => {
    if (!isSnackbarActive) {
      setIsSnackbarActive(true);
      enqueueSnackbar(message, {
        ...options,
        onClose: (reason, key) => {
          setIsSnackbarActive(false);
          if (options.onClose) {
            options.onClose(reason, key);
          }
        },
      });
    }
  };

  const buyClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (product && cart.findIndex((item: any) => item.p.id === product.id) === -1) {
      showSnackbar('', { variant: 'success' });
      dispatch(addCartItemWithCount({ p: product, c: count }));
      return;
    }

    const cartProduct = cart.find((item: any) => item.p.id === product?.id);
    if (product && cartProduct && cartProduct.c < 999) {
      showSnackbar('', { variant: 'success' });
      if (cartProduct.c + count > 999) {
        dispatch(addCartItemWithCount({ p: product, c: 999 - cartProduct.c }));
        return;
      }
      dispatch(addCartItemWithCount({ p: product, c: count }));
    }
  };

  return (
    <>
      {isAuth && <ModalWindow isActive={setIsAuth} />}
      {product === null ? (
        <div className="product-section-preloader">
          <Preloader />
        </div>
      ) : (
        <>
          {!product ? (
            <div className="product-section-preloader">
              <Preloader />
            </div>
          ) : (
            <>
              <div className="product-section-top page-container">
                <section className="product-section__gallery">
                  <div className="product-section__gallery__current">
                    <div className="product-section__gallery__current__label">
                      {currentCategory?.areMiningCharacteristics && <p>{product?.condition}</p>}

                      {product?.percentageDiscount > 0 && (
                        <span>- {product?.percentageDiscount}%</span>
                      )}
                    </div>

                    <img
                      id="product-current-img"
                      alt=""
                      src={product?.images[0].url || images.defaultImg}
                    />
                  </div>
                  <div className="product-section__gallery__list">
                    <div className="product-section__slider-image">
                      <ProductSlider setCurrent={changeImg} images={product?.images} />
                    </div>
                  </div>
                </section>
                <section className="product-section__info">
                  <div>
                    <div className="product-section__info-name">
                      <div className="product-section__info-name__firm-actions">
                        <div className="product-section__info-name__name">{product?.name}</div>
                        <div className="product-section__info-name__actions">
                          {/* <CompareBtn product={product} categoryId={product.categoryId} /> */}
                          <WishlistBtn setIsAuthModalOpen={setIsAuth} productId={product.id} />
                        </div>
                      </div>
                      <div className="product-section__info-name__firm">
                        {product?.manufacturer}
                      </div>
                    </div>
                    <div className="product-section__info-price">
                      <div className="product-section__info-price__price">
                        {product?.percentageDiscount === 0 ? (
                          `${(product?.price * count).toFixed(2)}$`
                        ) : (
                          <>
                            <span className="product-section__info-price__price-without-discount">{`${(
                              product?.price * count
                            ).toFixed(2)}$`}</span>
                            <span className="product-section__info-price__price-with-discount">{`${(
                              product?.price * count -
                              product?.price * count * (product?.percentageDiscount / 100)
                            ).toFixed(2)}$`}</span>
                          </>
                        )}
                      </div>
                      <div className="product-section__info-price__available">
                        <span>{product?.amount === 0 ? 'Pre-order' : 'Available'}</span>
                        {product?.batch !== null &&
                          product?.batch !== undefined &&
                          product?.batch !== '' && (
                            <span>
                              {new Date(product?.batch)?.toLocaleString('en-US', {
                                month: 'long',
                                year: 'numeric',
                              })}{' '}
                              Batch
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="product-section__info-characteristics">
                      {mainCharacteristics.map((characteristic) => (
                        <div
                          key={characteristic.name}
                          className="product-section__info-characteristics__characteristics"
                        >
                          <span className="product-section__info-characteristics__characteristics-name">
                            {characteristic.name}
                          </span>
                          <span className="product-section__info-characteristics__characteristics-value">
                            {currentCategory?.areMiningCharacteristics
                              ? (product && characteristic && product[characteristic.key]) || ''
                              : (characteristic && characteristic.value) || ''}
                          </span>
                        </div>
                      ))}
                      <div className="product-section__info-characteristics__counts-buttons">
                        <div className="product-section__info-characteristics__counts-buttons__counts unselectable">
                          <button
                            className="counts-buttons__counts-minus"
                            onClick={changeCount}
                            data-minus
                          >
                            -
                          </button>
                          <input
                            id={product.id}
                            type="number"
                            className="counts-buttons__counts-count"
                            defaultValue={count}
                            onChange={changeCountInput}
                            onBlur={setDefaultCount}
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) && e.preventDefault()
                            }
                          />
                          <button
                            className="counts-buttons__counts-plus"
                            onClick={changeCount}
                            data-plus
                          >
                            +
                          </button>
                        </div>
                        <div
                          className="product-section__info-characteristics__counts-buttons__text"
                          style={count >= 15 ? { color: '#25A8CF' } : { color: '#746A81' }}
                        >
                          * If you purchase 25 units or more of this product, you'll receive an
                          extra discount. We'll get in touch with you after you've placed your order
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-section__info-buttons">
                    <button className="product-section__info-buttons-buy " onClick={buyClick}>
                      <CartProdIcon />
                      Add to cart
                    </button>
                    <div className="cart-section-content__buttons__checkout__button-message">
                      <button
                        className="product-section__info-buttons-quick-order"
                        onClick={(e) => {
                          buyClick(e);
                          setIsAuth(true);
                          setIsQuickOrder(true);
                        }}
                      >
                        Quick Order
                      </button>
                    </div>
                  </div>
                </section>
              </div>
              <div className="product-section-guarantee ">
                <div className="product-section-guarantee__list">
                  <Features />
                </div>
              </div>
              <div className="product-section-main-info page-container">
                <Tabs product={product} />
              </div>
              <div className="product-section-contacts-service page-container">
                <div className="product-section-contacts-service-list">
                  <div className="product-section-contacts-service__contacts">
                    <div className="product-section-contacts-service__contacts__svg">
                      <svg
                        width="101"
                        height="65"
                        viewBox="0 0 101 65"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_3928_13431)">
                          <path
                            d="M72.1926 53.4017C78.5912 53.4143 83.7703 43.9108 83.7075 32.2325C83.6446 20.5542 78.5912 10.925 72.1926 10.9376L66.1925 10.9268C65.6395 10.9258 65.1907 11.3738 65.1907 11.9268L65.1907 23.426C65.1907 23.9783 65.6384 24.426 66.1907 24.426L70.219 24.426C71.2369 24.4336 72.2109 24.8413 72.9307 25.5611C73.6504 26.2808 74.0581 27.2548 74.0657 28.2727V36.0666C74.0614 37.0888 73.6534 38.0679 72.9306 38.7907C72.2078 39.5136 71.2287 39.9215 70.2064 39.9258H66.1781C65.6258 39.9258 65.1781 40.3735 65.1781 40.9258L65.1781 52.4233C65.1781 52.9769 65.628 53.4252 66.1817 53.4233L72.1926 53.4017Z"
                            fill="#ECF0F7"
                          />
                        </g>
                        <path
                          d="M57.7324 55.6665C57.5568 55.9706 57.3043 56.2231 57.0002 56.3986C56.696 56.5742 56.3511 56.6665 55.9999 56.6665H7.9999C7.649 56.6661 7.30437 56.5734 7.00062 56.3978C6.69687 56.2221 6.44468 55.9696 6.26939 55.6656C6.0941 55.3616 6.00186 55.0169 6.00195 54.666C6.00204 54.3151 6.09445 53.9704 6.2699 53.6665C10.0774 47.084 15.9449 42.364 22.7924 40.1265C19.4053 38.1101 16.7738 35.0377 15.3019 31.381C13.83 27.7242 13.5991 23.6855 14.6448 19.8849C15.6904 16.0842 17.9547 12.732 21.09 10.3428C24.2252 7.95361 28.0581 6.65967 31.9999 6.65967C35.9417 6.65967 39.7746 7.95361 42.9098 10.3428C46.0451 12.732 48.3094 16.0842 49.3551 19.8849C50.4007 23.6855 50.1698 27.7242 48.6979 31.381C47.226 35.0377 44.5945 38.1101 41.2074 40.1265C48.0549 42.364 53.9224 47.084 57.7299 53.6665C57.9058 53.9703 57.9987 54.3151 57.9991 54.6661C57.9995 55.0172 57.9076 55.3622 57.7324 55.6665Z"
                          fill="#ECF0F7"
                        />
                        <defs>
                          <clipPath id="clip0_3928_13431">
                            <rect
                              width="42.6667"
                              height="42.6667"
                              fill="white"
                              transform="matrix(-0.707107 0.707107 0.707107 0.707107 70.1689 2)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                    <div className="product-section-contacts-service__contacts__name">
                      AsicXchange Help Desk
                    </div>
                    <p className="product-section-contacts-service__contacts__text">
                      For assistance and inquiries, please contact us
                    </p>
                    <div className="product-section-contacts-service__contacts__info">
                      <div className="product-section-contacts-service__contacts__info-t">
                        <PhoneIcon />
                        <div>
                          <span>Phone:</span>
                          <span>{contacts?.phoneWeb}</span>
                        </div>
                      </div>
                      <div className="product-section-contacts-service__contacts__info-t">
                        <MailIcon />
                        <div>
                          <span>Email:</span>
                          <span>{contacts?.emailWeb}</span>
                        </div>
                      </div>
                    </div>
                    <p className="product-section-contacts-service__contacts__text">
                      Reach out for any questions or concerns
                    </p>
                  </div>
                  <div className="product-section-contacts-service__service">
                    <div className="product-section-contacts-service__service__name">
                      AsicXchange Services
                    </div>
                    <div className="product-section-contacts-service__service__list">
                      <ul>
                        {service.map((serv, index) => (
                          <li
                            className="product-section-contacts-service__service__list-item"
                            key={index}
                          >
                            <MarkerDoneIcon />
                            <span>{serv}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <p className="product-section-contacts-service__service__text">
                      AsicXchange: Seamless, reliable services for your needs
                    </p>
                  </div>
                </div>
              </div>
              <div className="product-section-also-like"></div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Product;
