import { DraftEntityMutability } from 'draft-js';

function converterFromHTML() {
  return {
    htmlToStyle: (nodeName: string, node: HTMLElement, currentStyle: Set<string>) => {
      if (nodeName === 'span') {
        if (node.style.textAlign === 'center') {
          return currentStyle.add('CENTER');
        }
        if (node.style.textAlign === 'left') {
          return currentStyle.add('LEFT');
        }
        if (node.style.textAlign === 'right') {
          return currentStyle.add('RIGHT');
        }
      }
      return currentStyle;
    },
    htmlToBlock: (nodeName: string): string | undefined => {
      if (nodeName === 'figure') {
        return 'atomic';
      }
      return undefined;
    },
    htmlToEntity: (
      nodeName: string,
      node: HTMLElement,
      createEntity: (type: string, mutability: DraftEntityMutability, data: object) => string,
    ): string | undefined => {
      if (nodeName === 'a') {
        return createEntity('LINK', 'IMMUTABLE', {
          url: (node as HTMLAnchorElement).href,
        });
      }

      if (nodeName === 'iframe') {
        return createEntity('draft-js-video-plugin-video', 'IMMUTABLE', {
          src: (node as HTMLIFrameElement).src,
        });
      }

      if (nodeName === 'img') {
        const style = node.getAttribute('style') || '';

        // Extract float from style
        const float = (str: string): string | null => {
          const floatMatch = str.match(/float\s*:\s*([^;]+)/);
          return floatMatch ? floatMatch[1].trim() : null;
        };

        // Extract width from style
        const width = (str: string): string | null => {
          const widthMatch = str.match(/width\s*:\s*([^;]+)/);
          return widthMatch ? widthMatch[1].trim() : null;
        };

        return createEntity('IMAGE', 'IMMUTABLE', {
          src: (node as HTMLImageElement).src,
          alignment: float(style) || 'none',
          width: parseInt(width(style) || '0', 10),
        });
      }

      return undefined;
    },
  };
}

export default converterFromHTML;
