import React from 'react';

const MiningBitcoinAd: React.FC = () => {
  return (
    <>
      <div className="mining_container">
        <h2 className="mining_header content">
          START MINING <span>BITCOIN</span> IN JUST <span>2 DAYS</span>
        </h2>
        <p className="mining_main_text content">
          Purchase an ASIC miner and begin earning within <span>48 hours.</span>
          <br />
          <span>Fast, easy, and accessible</span> to everyone.
        </p>
        <p className="mining_text content">
          Now everyone can mine Bitcoin. With our new rapid deployment service, you can start
          earning in as little as two days. Buy your miner today, and we’ll handle the rest—from
          installation to hosting and maintenance.
        </p>
        <div className="icons">
          <div className="space_ship icon"></div>
          <div className="touch icon"></div>
          <div className="people_group icon"></div>
        </div>
      </div>
      <button className="link">Get Started Now →</button>
    </>
  );
};

export default MiningBitcoinAd;
