import { AxiosInstance } from 'axios';

interface FandQService {
  getFAQGroup: () => Promise<any>;
  deleteFAQGroup: (id: string) => Promise<any>;
  deleteFAQ: (id: string) => Promise<any>;
  postFAQ: (body: { groupId: string; question: string; answer: string }) => Promise<any>;
  putFAQ: (body: { id: string; question: string; answer: string }) => Promise<any>;
  postFAQGroup: (name: string) => Promise<any>;
  putFAQGroup: (body: { id: string; name: string }) => Promise<any>;
  getQuestionAnswerGroup: () => Promise<any>;
  deleteQuestionAnswerGroup: (id: string) => Promise<any>;
  deleteQuestionAnswer: (id: string) => Promise<any>;
  postQuestionAnswerGroup: (name: string) => Promise<any>;
  putQuestionAnswerGroup: (body: { id: string; name: string }) => Promise<any>;
  postQuestionAnswer: (data: { groupId: string; question: string; answer: string }) => Promise<any>;
  putQuestionAnswer: (body: { id: string; question: string; answer: string }) => Promise<any>;
}

const createFandQService = (instance: AxiosInstance): FandQService => ({
  async putQuestionAnswer(body: { id: string; question: string; answer: string }) {
    try {
      const response = await instance.put(`/QuestionAnswer`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postQuestionAnswer(body: { groupId: string; question: string; answer: string }) {
    try {
      const response = await instance.post(`/QuestionAnswer`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putQuestionAnswerGroup(body: { id: string; name: string }) {
    try {
      const response = await instance.put(`/QuestionAnswerGroup`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postQuestionAnswerGroup(name: string) {
    try {
      const response = await instance.post(`/QuestionAnswerGroup`, { name: name });
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteQuestionAnswer(id: string) {
    const data = {
      id: id,
    };

    try {
      const response = await instance.delete(`/QuestionAnswer`, { data });
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteQuestionAnswerGroup(id: string) {
    const data = {
      id: id,
    };

    try {
      const response = await instance.delete(`/QuestionAnswerGroup`, {
        data,
      });
      return response;
    } catch (error) {
      return error;
    }
  },

  async getQuestionAnswerGroup() {
    try {
      const response = await instance.get(`/QuestionAnswerGroup/all`);
      return response;
    } catch (error) {
      return error;
    }
  },

  async putFAQGroup(body: { id: string; name: string }) {
    try {
      const response = await instance.put(`/FaqGroup`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postFAQGroup(name: string) {
    try {
      const response = await instance.post(`/FaqGroup`, { name: name });
      return response;
    } catch (error) {
      return error;
    }
  },

  async putFAQ(body: { id: string; question: string; answer: string }) {
    try {
      const response = await instance.put(`/Faq`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async postFAQ(body: { groupId: string; question: string; answer: string }) {
    try {
      const response = await instance.post(`/Faq`, body);
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteFAQ(id: string) {
    const data = {
      id: id,
    };

    try {
      const response = await instance.delete(`/Faq`, { data });
      return response;
    } catch (error) {
      return error;
    }
  },

  async deleteFAQGroup(id: string) {
    const data = {
      id: id,
    };

    try {
      const response = await instance.delete(`/FaqGroup`, { data });
      return response;
    } catch (error) {
      return error;
    }
  },

  async getFAQGroup() {
    try {
      const response = await instance.get(`/FaqGroup/all`);
      return response;
    } catch (error) {
      return error;
    }
  },
});

export default createFandQService;
