import React, { ReactNode } from 'react';

interface EntityData {
  type: string;
  data: {
    src?: string;
    url?: string;
    width?: number;
    alignment?: string;
  };
}

function converterToHTML(
  regetLinkUsed: RegExp,
  extractYouTubeVideoId: (url: string) => string | null,
) {
  return {
    styleToHTML: (style: string): ReactNode | undefined => {
      if (style === 'center') return <span style={{ display: 'block', textAlign: 'center' }} />;
      if (style === 'left') return <span style={{ display: 'block', textAlign: 'left' }} />;
      if (style === 'right') return <span style={{ display: 'block', textAlign: 'right' }} />;
      return undefined;
    },
    blockToHTML: (block: { type: string; text?: string }): ReactNode | undefined => {
      if (block.type === 'atomic') {
        return <figure style={{ margin: '0' }} />;
      }

      if (block.type === 'header-one') {
        return <h1 style={{ color: '#25a8cf', marginTop: '0px' }}>{block.text}</h1>;
      }

      if (block.type === 'header-two') {
        return <h2 style={{ color: '#25a8cf', marginTop: '0px' }}>{block.text}</h2>;
      }

      if (block.type === 'header-three') {
        return <h3 style={{ marginTop: '0px' }}>{block.text}</h3>;
      }

      if (block.type === 'unstyled' && block.text !== '') {
        return <p style={{ margin: '0' }} />;
      }

      if (block.text === '') return <br />;
      return undefined;
    },
    entityToHTML: (entity: EntityData, originalText: string): ReactNode | undefined => {
      const { type, data } = entity;
      const { src: localSrc, url: localUrl, width } = data;

      if (type === 'LINK') {
        return (
          <a
            style={{
              color: 'white',
              fontWeight: 'bold',
              textDecoration: 'underline',
            }}
            href={localUrl}
          >
            {originalText}
          </a>
        );
      }

      if (type === 'draft-js-video-plugin-video') {
        let src: string | undefined = localSrc;

        const isUsedLink = localSrc?.match(regetLinkUsed);
        if (isUsedLink === null) {
          const videoId = extractYouTubeVideoId(localSrc || '');
          if (videoId) {
            src = `https://www.youtube.com/embed/${videoId}?si=3OhWp80wWBCm0o9n`;
          }
        }
        const customStyles = {
          width: '100%',
          height: '500px',
          borderRadius: '10px',
        };
        return (
          <iframe
            style={{ ...customStyles, border: 'none' }}
            src={src}
            title={src}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
          ></iframe>
        );
      }

      if (type === 'IMAGE') {
        const float = data.alignment || 'none';
        const lastUrl = localSrc || '';

        const maxViewPortWidth = width && width >= 100 ? 100 : width || 100;
        const customStyles: any = {
          width: `${maxViewPortWidth}%`,
          borderRadius: '10px',
          display: 'flex',
          float: `${float}`,
          margin: `${
            float === 'left'
              ? '8px 20px 0px 0px'
              : float === 'center'
                ? '8px auto 0px auto'
                : float === 'right'
                  ? '8px 0px 0px 20px'
                  : '8px 20px 0px 0px'
          }`,
        };

        return <img src={lastUrl} alt="" style={customStyles} />;
      }

      return undefined;
    },
  };
}

export default converterToHTML;
