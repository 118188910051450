import './AdminModalWindow.scss';

import React, { ReactNode } from 'react';

import CloseBig from '../../../general/assets/svg/CloseBig';
import useKey, { keys } from '../../../general/hooks/useKey';

interface AdminModalWindowProps {
  children: ReactNode;
  isOpen: (isOpen: boolean) => void;
  title: string;
  mWidth?: string;
  saveCallback?: () => void;
}

const AdminModalWindow: React.FC<AdminModalWindowProps> = ({
  children,
  isOpen,
  title,
  mWidth,
  saveCallback,
}) => {
  const closeModal = () => {
    isOpen(false);
  };

  useKey((event) => {
    if (event.key === keys.ESCAPE) {
      isOpen && isOpen(false);
      closeModal();
    }

    if (event.key === keys.ENTER) {
      if (saveCallback) {
        saveCallback && saveCallback();
        isOpen && isOpen(false);
        closeModal();
      }
    }
  });

  return (
    <div
      className="modal-window-action"
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <div className="modal-window-action__overlay" onClick={closeModal} />
      <div className="modal-window-action__modal__home" style={{ width: mWidth }}>
        <div className="modal-window-action__modal__title">
          <span>{title}</span>
          <button style={{ justifyContent: 'end' }} onClick={closeModal}>
            <CloseBig />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default AdminModalWindow;
